// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import { doc, getDoc } from "firebase/firestore";
// import Skeleton from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css"; // Import skeleton styles
// import { db } from "../../firebase";
// import "../blog_stepper/blog_stepper.css"
// import img1 from "../../img/Designer (8) 1.png"

// const BlogIndiView = () => {
//   const { id } = useParams(); // Get the blog ID from the URL
//   const [blogData, setBlogData] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchBlog = async () => {
//       setLoading(true);
//       try {
//         const blogDocRef = doc(db, "blogs", id); // Fetch the specific blog by ID
//         const blogDoc = await getDoc(blogDocRef);
//         if (blogDoc.exists()) {
//           setBlogData(blogDoc.data());
//         } else {
//           console.error("Blog not found!");
//         }
//       } catch (error) {
//         console.error("Error fetching blog:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchBlog();
//   }, [id]);

//   if (loading) {
//     return (
//       <div style={{ marginTop: "20px", width: "75%" }}>
//         <h4>Blog</h4>
//         <p>
//           <Skeleton width={100} />
//         </p>
//         <h4>
//           <Skeleton width="50%" />
//         </h4>
//         <Skeleton height={300} />
//         <div>
//           {Array(3)
//             .fill(0)
//             .map((_, index) => (
//               <div key={index} style={{ marginBottom: "15px" }}>
//                 <Skeleton
//                   count={5}
//                   height={60}
//                   style={{ marginBottom: "15px" }}
//                 />
//                 <Skeleton
//                   count={5}
//                   height={60}
//                   style={{ marginBottom: "15px" }}
//                 />
//                 <Skeleton
//                   count={5}
//                   height={60}
//                   style={{ marginBottom: "15px" }}
//                 />
//               </div>
//             ))}
//         </div>
//       </div>
//     );
//   }

//   if (!blogData) {
//     return <p>Blog not found!</p>;
//   }

//   const { section, mainTitle, subContent, titleImage } = blogData;

//   return (
//     <div className="blog-indi-cont">
//       {/* <h4>Blog</h4> */}
//       <p>
//         <strong>Section:</strong> {section == "case_studies" ? "Case Studies" : "Blog"}
//       </p>
//       <h4>
//         <strong>{mainTitle}</strong>
//       </h4>
//       <img
//         src={titleImage || img1}
//         alt="Title Preview"
//         style={{ width: "100%" ,marginTop:"1rem",marginBottom:'2rem'}}
//       />
//       <div>
//         {subContent.map((item, index) => (
//           <div key={index} style={{ marginBottom: "15px" }}>
//             {item.type === "ParagraphWithTitle" && (
//               <div>
//                 <h5>{item.title}</h5>
//                 <p>{item.content}</p>
//               </div>
//             )}
//             {item.type === "OnlyParagraph" && <p>{item.content}</p>}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default BlogIndiView;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DOMPurify from "dompurify";
import { db } from "../../firebase";
import "../blog_stepper/blog_stepper.css";
import img1 from "../../img/Designer (8) 1.png";

const BlogIndiView = () => {
  const { id } = useParams(); // Get the blog ID from the URL
  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlog = async () => {
      setLoading(true);
      try {
        const blogDocRef = doc(db, "blogs", id); // Fetch the specific blog by ID
        const blogDoc = await getDoc(blogDocRef);
        if (blogDoc.exists()) {
          setBlogData(blogDoc.data());
        } else {
          console.error("Blog not found!");
        }
      } catch (error) {
        console.error("Error fetching blog:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  if (loading) {
    return (
      <div style={{ marginTop: "20px", width: "75%" }}>
        <h4>Blog</h4>
        <p>
          <Skeleton width={100} />
        </p>
        <h4>
          <Skeleton width="50%" />
        </h4>
        <Skeleton height={300} />
        <div>
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <div key={index} style={{ marginBottom: "15px" }}>
                <Skeleton
                  count={5}
                  height={60}
                  style={{ marginBottom: "15px" }}
                />
                <Skeleton
                  count={5}
                  height={60}
                  style={{ marginBottom: "15px" }}
                />
                <Skeleton
                  count={5}
                  height={60}
                  style={{ marginBottom: "15px" }}
                />
              </div>
            ))}
        </div>
      </div>
    );
  }

  if (!blogData) {
    return <p>Blog not found!</p>;
  }

  const { section, mainTitle, subContent, titleImage } = blogData;

  return (
    <div className="blog-indi-cont">
      <p>
        <strong>Section:</strong> {section == "case_studies" ? "Case Studies" : "Blog"}
      </p>
      <h4>
        <strong>{mainTitle}</strong>
      </h4>
      <img
        src={titleImage || img1}
        alt="Title Preview"
        style={{ width: "100%" ,marginTop:"1rem",marginBottom:'2rem'}}
      />
      <div>
        {subContent.map((item, index) => (
          <div key={index} style={{ marginBottom: "15px" }}>
            {item.type === "ParagraphWithTitle" && (
              <div>
                <h5>{item.title}</h5>
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(item.content),
                  }}
                />
              </div>
            )}
            {item.type === "OnlyParagraph" && (
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(item.content),
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogIndiView;
