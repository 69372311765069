import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // For navigation
import "./admin_login_detail.css";
import coreImg from "../../img/admin_header.png";

function AdminLoginDetail() {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    // Hard-coded login credentials
    if (name === "admin" && password === "Password@123") {
      sessionStorage.setItem("isLoggedIn", "true"); // Save login state in sessionStorage
      navigate("/adminMenu"); // Redirect to adminMenu page
    } else {
      alert("Invalid credentials! Please try again."); // Show error for invalid login
    }
  };

  return (
    <div className="whoWeAreMain-o-ad">
      <div className="core-value-cont-ad">
        <div className="core-value-up-ad">
          <img className="core-img-ad" src={coreImg} alt="Admin Header" />
        </div>
      </div>
      <div className="login-container">
        <form className="login-form" onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              placeholder="Enter Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form-links">
            <a href="/forgot-password" className="forgot-password">
              Forgot Password
            </a>
          </div>
          <button type="submit" className="login-button">
            Login
          </button>
        </form>
      </div>
    </div>
  );
}

export default AdminLoginDetail;
