// import { useLocation } from "react-router-dom";
// import React, { useState } from "react";
// import BlogContentStep from "./blog_content_step";
// import BlogImageStep from "./blog_image_step";
// import BlogPreviewStep from "./blog_preview_step";
// import Topbar from "../topbar/topbar";
// import { Container, Row, Col, Button, ProgressBar } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { collection, addDoc, serverTimestamp,updateDoc,doc } from "firebase/firestore";
// import { db } from "../../firebase";

// const steps = ["Content", "Images", "Preview"];

// const EditBlogStepper = () => {
//   const { state } = useLocation();
//   const [activeStep, setActiveStep] = useState(0);
//   const blogDataFromState = state?.blog || {};
//   const [blogData, setBlogData] = useState({
//     section: blogDataFromState.section || "",
//     mainTitle: blogDataFromState.mainTitle || "",
//     subContent: blogDataFromState.subContent || [],
//     titleImage: blogDataFromState.titleImage || "",
//     cardImage: blogDataFromState.cardImage || "",
//   });

//   const handleNext = () => {
//     if (activeStep < steps.length - 1) {
//       setActiveStep((prev) => prev + 1);
//     }
//   };

//   const handleBack = () => {
//     if (activeStep > 0) {
//       setActiveStep((prev) => prev - 1);
//     }
//   };

//   const handleDataUpdate = (updatedData) => setBlogData(updatedData);

//   // const handleSubmit = async () => {
//   //   const { titleImage, cardImage, ...dataToSave } = blogData;
//   //   const blogDataWithTimestamp = {
//   //     ...dataToSave,
//   //     createdAt: serverTimestamp(),
//   //   };

//   //   console.log("Blog data being sent:", blogDataWithTimestamp);

//   //   try {
//   //     await addDoc(collection(db, "blogs"), blogDataWithTimestamp);
//   //     console.log("Blog updated successfully!");
//   //     alert("Blog updated successfully!");
//   //   } catch (error) {
//   //     console.error("Error saving blog:", error);
//   //     alert("Failed to save blog. Please try again.");
//   //   }
//   // };

//   const handleSubmit = async () => {
//     const { titleImage, cardImage, ...dataToSave } = blogData;
//     const blogDataWithTimestamp = {
//       ...dataToSave,
//       updatedAt: serverTimestamp(), // Use updatedAt for updates
//     };

//     try {
//       if (state?.blog?.id) {
//         // If an ID exists, update the existing document
//         const blogRef = doc(db, "blogs", state.blog.id); // Access the specific document
//         await updateDoc(blogRef, blogDataWithTimestamp);
//         console.log("Blog updated successfully!");
//         alert("Blog updated successfully!");
//       } else {
//         // If no ID exists, create a new document
//         const newDoc = await addDoc(collection(db, "blogs"), {
//           ...blogDataWithTimestamp,
//           createdAt: serverTimestamp(),
//         });
//         console.log("New blog created with ID:", newDoc.id);
//         alert("New blog created successfully!");
//       }
//     } catch (error) {
//       console.error("Error saving blog:", error);
//       alert("Failed to save blog. Please try again.");
//     }
//   };

//   return (
//     <div>
//       <Topbar />
//       <Container fluid className="p-3 create-blog-container">
//         <h2 className="text-center marg-l">Edit Blog</h2>

//         {/* Stepper Panel */}
//         <Row className="mb-4">
//           <Col>
//             <ProgressBar now={(activeStep / (steps.length - 1)) * 100} />
//             <div className="d-flex justify-content-between mt-3">
//               {steps.map((label, index) => (
//                 <div
//                   key={label}
//                   className={`text-center ${
//                     index === activeStep
//                       ? "text-primary fw-bold"
//                       : index < activeStep
//                       ? "text-success fw-bold"
//                       : "text-muted"
//                   }`}
//                 >
//                   <div
//                     className={`rounded-circle d-inline-flex align-items-center justify-content-center mb-2 ${
//                       index <= activeStep ? "bg-primary text-white" : "bg-light"
//                     }`}
//                     style={{
//                       width: "30px",
//                       height: "30px",
//                       border: "1px solid #ccc",
//                     }}
//                   >
//                     {index + 1}
//                   </div>
//                   <div>{label}</div>
//                 </div>
//               ))}
//             </div>
//           </Col>
//         </Row>

//         {/* Step Content */}
//         <Row>
//           <Col>
//             {activeStep === 0 && (
//               <BlogContentStep
//                 blogData={blogData}
//                 onUpdate={handleDataUpdate}
//               />
//             )}
//             {activeStep === 1 && (
//               <BlogImageStep blogData={blogData} onUpdate={handleDataUpdate} />
//             )}
//             {activeStep === 2 && <BlogPreviewStep blogData={blogData} />}
//           </Col>
//         </Row>

//         {/* Navigation Buttons */}
//         <Row className="mt-4">
//           <Col className="d-flex justify-content-between">
//             <Button
//               style={{ width: "10%" }}
//               variant="secondary"
//               onClick={handleBack}
//               disabled={activeStep === 0}
//             >
//               Back
//             </Button>
//             {activeStep === steps.length - 1 ? (
//               <Button
//                 style={{ width: "10%" }}
//                 variant="primary"
//                 onClick={handleSubmit}
//               >
//                 update
//               </Button>
//             ) : (
//               <Button
//                 style={{ width: "10%" }}
//                 variant="primary"
//                 onClick={handleNext}
//               >
//                 Next
//               </Button>
//             )}
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// };

// export default EditBlogStepper;

// import { useLocation } from "react-router-dom";
// import React, { useState } from "react";
// import BlogContentStep from "./blog_content_step";
// import BlogImageStep from "./blog_image_step";
// import BlogPreviewStep from "./blog_preview_step";
// import Topbar from "../topbar/topbar";
// import { Container, Row, Col, Button, ProgressBar, Spinner } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { collection, addDoc, serverTimestamp, updateDoc, doc } from "firebase/firestore";
// import { db } from "../../firebase";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const steps = ["Content", "Images", "Preview"];

// const EditBlogStepper = () => {
//   const { state } = useLocation();
//   const [activeStep, setActiveStep] = useState(0);
//   const blogDataFromState = state?.blog || {};
//   const [blogData, setBlogData] = useState({
//     section: blogDataFromState.section || "",
//     mainTitle: blogDataFromState.mainTitle || "",
//     subContent: blogDataFromState.subContent || [],
//     titleImage: blogDataFromState.titleImage || "",
//     cardImage: blogDataFromState.cardImage || "",
//   });

//   // State for loading
//   const [isLoading, setIsLoading] = useState(false);

//   const handleNext = () => {
//     if (activeStep < steps.length - 1) {
//       setActiveStep((prev) => prev + 1);
//     }
//   };

//   const handleBack = () => {
//     if (activeStep > 0) {
//       setActiveStep((prev) => prev - 1);
//     }
//   };

//   const handleDataUpdate = (updatedData) => setBlogData(updatedData);

//   const handleSubmit = async () => {
//     const { titleImage, cardImage, ...dataToSave } = blogData;
//     const blogDataWithTimestamp = {
//       ...dataToSave,
//       updatedAt: serverTimestamp(), // Use updatedAt for updates
//     };

//     setIsLoading(true); // Set loading to true while submitting

//     try {
//       if (state?.blog?.id) {
//         // If an ID exists, update the existing document
//         const blogRef = doc(db, "blogs", state.blog.id);
//         await updateDoc(blogRef, blogDataWithTimestamp);
//         toast.success("Blog updated successfully!", {
//           position: "top-center",
//           autoClose: 3000,
//           style:{marginTop:"2rem"},
//           hideProgressBar: false,
//         });
//       } else {
//         // If no ID exists, create a new document
//         const newDoc = await addDoc(collection(db, "blogs"), {
//           ...blogDataWithTimestamp,
//           createdAt: serverTimestamp(),
//         });
//         toast.success("Blog added successfully!", {
//           position: "top-center",
//           autoClose: 3000,
//           style:{marginTop:"2rem"},
//           hideProgressBar: false,
//         });
//       }
//     } catch (error) {
//       console.error("Error saving blog:", error);
//       toast.error("Failed to save blog. Please try again.", {
//         position: "top-right",
//         autoClose: 3000,
//         hideProgressBar: false,
//       });
//     } finally {
//       setIsLoading(false); // Reset loading state after operation
//     }
//   };

//   return (
//     <div>
//       <Topbar />
//       <Container fluid className="p-3 create-blog-container">
//         <ToastContainer />
//         <h2 className="text-center marg-l">Edit Blog</h2>

//         {/* Stepper Panel */}
//         <Row className="mb-4">
//           <Col>
//             <ProgressBar now={(activeStep / (steps.length - 1)) * 100} />
//             <div className="d-flex justify-content-between mt-3">
//               {steps.map((label, index) => (
//                 <div
//                   key={label}
//                   className={`text-center ${
//                     index === activeStep
//                       ? "text-primary fw-bold"
//                       : index < activeStep
//                       ? "text-success fw-bold"
//                       : "text-muted"
//                   }`}
//                 >
//                   <div
//                     className={`rounded-circle d-inline-flex align-items-center justify-content-center mb-2 ${
//                       index <= activeStep ? "bg-primary text-white" : "bg-light"
//                     }`}
//                     style={{
//                       width: "30px",
//                       height: "30px",
//                       border: "1px solid #ccc",
//                     }}
//                   >
//                     {index + 1}
//                   </div>
//                   <div>{label}</div>
//                 </div>
//               ))}
//             </div>
//           </Col>
//         </Row>

//         {/* Step Content */}
//         <Row>
//           <Col>
//             {activeStep === 0 && (
//               <BlogContentStep
//                 blogData={blogData}
//                 onUpdate={handleDataUpdate}
//               />
//             )}
//             {activeStep === 1 && (
//               <BlogImageStep blogData={blogData} onUpdate={handleDataUpdate} />
//             )}
//             {activeStep === 2 && <BlogPreviewStep blogData={blogData} />}
//           </Col>
//         </Row>

//         {/* Navigation Buttons */}
//         <Row className="mt-4">
//           <Col className="d-flex justify-content-between">
//             <Button
//               style={{ width: "10%" }}
//               variant="secondary"
//               onClick={handleBack}
//               disabled={activeStep === 0}
//             >
//               Back
//             </Button>
//             {activeStep === steps.length - 1 ? (
//               <Button
//                 style={{ width: "10%" }}
//                 variant="primary"
//                 onClick={handleSubmit}
//                 disabled={isLoading} // Disable button when loading
//               >
//                 {isLoading ? (
//                   <Spinner
//                     as="span"
//                     animation="border"
//                     size="sm"
//                     role="status"
//                     aria-hidden="true"
//                   />
//                 ) : (
//                   "Update"
//                 )}
//               </Button>
//             ) : (
//               <Button
//                 style={{ width: "10%" }}
//                 variant="primary"
//                 onClick={handleNext}
//               >
//                 Next
//               </Button>
//             )}
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// };

// export default EditBlogStepper;

import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import BlogContentStep from "./blog_content_step";
import BlogImageStep from "./blog_image_step";
import BlogPreviewStep from "./blog_preview_step";
import Topbar from "../topbar/topbar";
import {
  Container,
  Row,
  Col,
  Button,
  ProgressBar,
  Spinner,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  collection,
  addDoc,
  serverTimestamp,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../firebase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const steps = ["Content", "Images", "Preview"];

const EditBlogStepper = () => {
  const { state } = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const blogDataFromState = state?.blog || {};
  const [blogData, setBlogData] = useState({
    section: blogDataFromState.section || "",
    mainTitle: blogDataFromState.mainTitle || "",
    subContent: blogDataFromState.subContent || [],
    titleImage: blogDataFromState.titleImage || "",
    cardImage: blogDataFromState.cardImage || "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const storage = getStorage(); // Initialize Firebase storage

  useEffect(() => {
    // Fetch the blog data, including images, if editing an existing blog
    if (state?.blog?.id) {
      const fetchBlogData = async () => {
        try {
          const blogRef = doc(db, "blogs", state.blog.id);
          const blogSnap = await getDoc(blogRef);
          if (blogSnap.exists()) {
            const data = blogSnap.data();
            setBlogData((prev) => ({
              ...prev,
              ...data,
            }));
          } else {
            console.log("No such blog document!");
          }
        } catch (error) {
          console.error("Error fetching blog:", error);
        }
      };
      fetchBlogData();
    }
  }, [state?.blog?.id]);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  const handleDataUpdate = (updatedData) => setBlogData(updatedData);

  const uploadImage = async (imageFile) => {
    if (!imageFile) return null;

    const imageRef = ref(storage, `blogImages/${imageFile.name}`);
    try {
      await uploadBytes(imageRef, imageFile);
      const downloadURL = await getDownloadURL(imageRef);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Failed to upload image. Please try again.", {
        position: "top-center",
        autoClose: 3000,
        style: { marginTop: "100px" },
      });
      return null;
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const { titleImage, cardImage, ...dataToSave } = blogData;

      // Upload new images if provided
      let titleImageURL = titleImage;
      let cardImageURL = cardImage;

      if (titleImage instanceof File) {
        titleImageURL = await uploadImage(titleImage);
      }

      if (cardImage instanceof File) {
        cardImageURL = await uploadImage(cardImage);
      }

      const blogDataWithTimestamp = {
        ...dataToSave,
        titleImage: titleImageURL,
        cardImage: cardImageURL,
        updatedAt: serverTimestamp(),
      };

      if (state?.blog?.id) {
        // If editing an existing blog
        const blogRef = doc(db, "blogs", state.blog.id);
        await updateDoc(blogRef, blogDataWithTimestamp);
        toast.success("Blog updated successfully!", {
          position: "top-center",
          autoClose: 3000,
          style: { marginTop: "2rem" },
        });
      } else {
        // Add a new blog if no ID exists
        await addDoc(collection(db, "blogs"), {
          ...blogDataWithTimestamp,
          createdAt: serverTimestamp(),
        });
        toast.success("Blog added successfully!", {
          position: "top-center",
          autoClose: 3000,
          style: { marginTop: "2rem" },
        });
      }
    } catch (error) {
      console.error("Error saving blog:", error);
      if (error.message.includes("1048487")) {
        toast.error(`Please Upload Image less than 450 kb`, {
          position: "top-center",
          autoClose: 3000,
          style: { marginTop: "100px" },
        });
      } else {
        toast.error(`Failed to save blog. Please try again.`, {
          position: "top-center",
          autoClose: 3000,
          style: { marginTop: "100px" },
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Topbar />
      <Container fluid className="p-3 create-blog-container">
        <ToastContainer />
        <h2 className="text-center marg-l">Edit Blog</h2>

        <Row className="mb-4">
          <Col>
            <ProgressBar now={(activeStep / (steps.length - 1)) * 100} />
            <div className="d-flex justify-content-between mt-3">
              {steps.map((label, index) => (
                <div key={label} className="text-center">
                  <div
                    className={`rounded-circle ${
                      index <= activeStep ? "bg-primary text-white" : "bg-light"
                    }`}
                    style={{
                      width: "30px",
                      height: "30px",
                      border: "1px solid #ccc",
                    }}
                  >
                    {index + 1}
                  </div>
                  <div>{label}</div>
                </div>
              ))}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            {activeStep === 0 && (
              <BlogContentStep
                blogData={blogData}
                onUpdate={handleDataUpdate}
              />
            )}
            {activeStep === 1 && (
              <BlogImageStep blogData={blogData} onUpdate={handleDataUpdate} />
            )}
            {activeStep === 2 && <BlogPreviewStep blogData={blogData} />}
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="d-flex justify-content-between">
            <Button
              variant="secondary"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              Back
            </Button>
            <Button
              variant="primary"
              onClick={
                activeStep === steps.length - 1 ? handleSubmit : handleNext
              }
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : activeStep === steps.length - 1 ? (
                "Update"
              ) : (
                "Next"
              )}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditBlogStepper;
