

// import React, { useState } from "react";
// import "./blog_stepper.css";
// import defImg1 from "../../img/default_image_lg.png"
// import defImg2 from "../../img/default_image_sm.png"

// const BlogImageStep = () => {
//   // Default images for title and card
//   const defaultTitleImage = defImg1; // Replace with actual path
//   const defaultCardImage = defImg2; // Replace with actual path

//   const [titleImage, setTitleImage] = useState(defaultTitleImage);
//   const [cardImage, setCardImage] = useState(defaultCardImage);

//   const handleImageUpload = (e, type) => {
//     const file = e.target.files[0];
//     const reader = new FileReader();

//     reader.onloadend = () => {
//       if (type === "title") {
//         setTitleImage(reader.result);
//       } else if (type === "card") {
//         setCardImage(reader.result);
//       }
//     };

//     if (file) {
//       reader.readAsDataURL(file);
//     }
//   };

//   return (
//     <div style={{ marginTop: "20px" }}>
//       <h4>Image Upload Section</h4>
      
//       {/* Title Image */}
//       <div className="image-upload-container">
//         <h5>Title Image</h5>
//         <input
//           type="file"
//           accept="image/*"
//           onChange={(e) => handleImageUpload(e, "title")}
//           style={{ display: "none" }}
//           id="title-image-upload"
//         />
//         <label htmlFor="title-image-upload" className="image-upload-label">
//           <img
//             src={titleImage} 
//             alt="Title Preview" 
//             className="image-preview" 
//             style={titleImage === defaultTitleImage ? {} : { objectFit: "cover" }}
//           />
//         </label>
//       </div>

//       {/* Image on Card */}
//       <div className="image-upload-container">
//         <h5>Image on Card</h5>
//         <input
//           type="file"
//           accept="image/*"
//           onChange={(e) => handleImageUpload(e, "card")}
//           style={{ display: "none" }}
//           id="card-image-upload"
//         />
//         <label htmlFor="card-image-upload" className="image-upload-label">
//           <img
//             src={cardImage} 
//             alt="Card Preview" 
//             className="image-preview-sm"
//             style={cardImage === defaultCardImage ? {} : { objectFit: "cover" }}
//           />
//         </label>
//       </div>
//     </div>
//   );
// };

// export default BlogImageStep;


import React, { useState } from "react";
import "./blog_stepper.css";
import defImg1 from "../../img/default_image_lg.png";
import defImg2 from "../../img/default_image_sm.png";

const BlogImageStep = ({ blogData, onUpdate }) => {
  const defaultTitleImage = defImg1;
  const defaultCardImage = defImg2;

  const [titleImage, setTitleImage] = useState(blogData.titleImage || defaultTitleImage);
  const [cardImage, setCardImage] = useState(blogData.cardImage || defaultCardImage);

  const handleImageUpload = (e, type) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      if (type === "title") {
        setTitleImage(reader.result);
        onUpdate({ ...blogData, titleImage: reader.result });
      } else if (type === "card") {
        setCardImage(reader.result);
        onUpdate({ ...blogData, cardImage: reader.result });
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <h4>Image Upload Section</h4>
      
      {/* Title Image */}
      <div className="image-upload-container">
        <h5>Title Image</h5>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => handleImageUpload(e, "title")}
          style={{ display: "none" }}
          id="title-image-upload"
        />
        <label htmlFor="title-image-upload" className="image-upload-label">
          <img
            src={titleImage}
            alt="Title Preview"
            className="image-preview"
            style={titleImage === defaultTitleImage ? {} : { objectFit: "cover" }}
          />
        </label>
      </div>

      {/* Image on Card */}
      <div className="image-upload-container">
        <h5>Image on Card</h5>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => handleImageUpload(e, "card")}
          style={{ display: "none" }}
          id="card-image-upload"
        />
        <label htmlFor="card-image-upload" className="image-upload-label">
          <img
            src={cardImage}
            alt="Card Preview"
            className="image-preview-sm"
            style={cardImage === defaultCardImage ? {} : { objectFit: "cover" }}
          />
        </label>
      </div>
    </div>
  );
};

export default BlogImageStep;
