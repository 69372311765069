

// import React, { useState } from "react";
// import { Form, Button, Card, Row, Col } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";

// const BlogContentStep = ({ blogData, onUpdate }) => {
//   const [subContent, setSubContent] = useState(blogData.subContent || []);
  
//   const handleAddSubContent = (type) => {
//     const newSubContent =
//       type === "OnlyParagraph"
//         ? { type: "OnlyParagraph", content: "" }
//         : { type: "ParagraphWithTitle", title: "", content: "" };

//     setSubContent([...subContent, newSubContent]);
//     onUpdate({ ...blogData, subContent: [...subContent, newSubContent] });
//   };

//   const handleSubContentChange = (index, field, value) => {
//     const updatedSubContent = [...subContent];
//     if (field === "title") {
//       updatedSubContent[index].title = value;
//     } else if (field === "content") {
//       updatedSubContent[index].content = value;
//     }
//     setSubContent(updatedSubContent);
//     onUpdate({ ...blogData, subContent: updatedSubContent });
//   };

//   const handleDeleteSubContent = (index) => {
//     const updatedSubContent = subContent.filter((_, i) => i !== index);
//     setSubContent(updatedSubContent);
//     onUpdate({ ...blogData, subContent: updatedSubContent });
//   };

//   return (
//     <div className="mt-4">
//       <h3>Create Blog</h3>
//       <Form>
//         <Form.Group className="mb-3">
//           <Form.Label>Section</Form.Label>
//           <Form.Select
//             value={blogData.section}
//             onChange={(e) => onUpdate({ ...blogData, section: e.target.value })}
//           >
//             <option value="">Select Section</option>
//             <option value="News">News</option>
//             <option value="Updates">Updates</option>
//           </Form.Select>
//         </Form.Group>

//         <Form.Group className="mb-3">
//           <Form.Label>Main Title</Form.Label>
//           <Form.Control
//             type="text"
//             placeholder="Enter main title"
//             value={blogData.mainTitle}
//             onChange={(e) => onUpdate({ ...blogData, mainTitle: e.target.value })}
//           />
//         </Form.Group>

//         <h4>Sub Content</h4>
//         {subContent.map((item, index) => (
//           <Card key={index} className="mb-3 shadow-sm">
//             <Card.Body>
//               <Row className="align-items-center">
//                 <Col md={11}>
//                   {item.type === "ParagraphWithTitle" && (
//                     <Form.Group className="mb-2">
//                       <Form.Label>Paragraph Title</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Sub Title"
//                         value={item.title}
//                         onChange={(e) =>
//                           handleSubContentChange(index, "title", e.target.value)
//                         }
//                       />
//                     </Form.Group>
//                   )}
//                   <Form.Group>
//                     <Form.Label>Paragraph</Form.Label>
//                     <Form.Control
//                       as="textarea"
//                       rows={3}
//                       placeholder="Enter content here"
//                       value={item.content}
//                       onChange={(e) =>
//                         handleSubContentChange(index, "content", e.target.value)
//                       }
//                     />
//                   </Form.Group>
//                 </Col>
//                 <Col md={1} className="text-end">
//                   <Button
//                     variant="danger"
//                     size="lg"
//                     onClick={() => handleDeleteSubContent(index)}
//                   >
//                     <i className="fas fa-trash-alt"></i>
//                   </Button>
//                 </Col>
//               </Row>
//             </Card.Body>
//           </Card>
//         ))}

//         <div className="d-flex gap-2">
//           <Button
//             variant="outline-primary"
//             onClick={() => handleAddSubContent("OnlyParagraph")}
//           >
//             + Add Only Paragraph
//           </Button>
//           <Button
//             variant="outline-primary"
//             onClick={() => handleAddSubContent("ParagraphWithTitle")}
//           >
//             + Add Paragraph with Title
//           </Button>
//         </div>
//       </Form>
//     </div>
//   );
// };

// export default BlogContentStep;


// import React, { useState } from "react";
// import { Form, Button, Card, Row, Col } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";

// const BlogContentStep = ({ blogData, onUpdate }) => {
//   const [subContent, setSubContent] = useState(blogData.subContent || []);

//   const handleAddSubContent = (type) => {
//     const newSubContent =
//       type === "OnlyParagraph"
//         ? { type: "OnlyParagraph", content: "" }
//         : { type: "ParagraphWithTitle", title: "", content: "" };

//     setSubContent([...subContent, newSubContent]);
//     onUpdate({ ...blogData, subContent: [...subContent, newSubContent] });
//   };

//   const handleSubContentChange = (index, field, value) => {
//     const updatedSubContent = [...subContent];
//     if (field === "title") {
//       updatedSubContent[index].title = value;
//     } else if (field === "content") {
//       updatedSubContent[index].content = value;
//     }
//     setSubContent(updatedSubContent);
//     onUpdate({ ...blogData, subContent: updatedSubContent });
//   };

//   const handleDeleteSubContent = (index) => {
//     const updatedSubContent = subContent.filter((_, i) => i !== index);
//     setSubContent(updatedSubContent);
//     onUpdate({ ...blogData, subContent: updatedSubContent });
//   };

//   return (
//     <div className="mt-4">
//       <h3>Create Blog</h3>
//       <Form>
//         <Form.Group className="mb-3">
//           <Form.Label>Section</Form.Label>
//           <Form.Select
//             value={blogData.section}
//             onChange={(e) => onUpdate({ ...blogData, section: e.target.value })}
//           >
//             <option value="">Select Section</option>
//             <option value="case_studies">Case Studies</option>
//             <option value="blogs">Blogs</option>
//           </Form.Select>
//         </Form.Group>

//         <Form.Group className="mb-3">
//           <Form.Label>Main Title</Form.Label>
//           <Form.Control
//             type="text"
//             placeholder="Enter main title"
//             value={blogData.mainTitle}
//             onChange={(e) => onUpdate({ ...blogData, mainTitle: e.target.value })}
//           />
//         </Form.Group>

//         <h4>Sub Content</h4>
//         {subContent.map((item, index) => (
//           <Card key={index} className="mb-3 shadow-sm">
//             <Card.Body>
//               <Row className="align-items-center">
//                 <Col md={11}>
//                   {item.type === "ParagraphWithTitle" && (
//                     <Form.Group className="mb-2">
//                       <Form.Label>Paragraph Title</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Sub Title"
//                         value={item.title}
//                         onChange={(e) =>
//                           handleSubContentChange(index, "title", e.target.value)
//                         }
//                       />
//                     </Form.Group>
//                   )}
//                   <Form.Group>
//                     <Form.Label>Paragraph</Form.Label>
//                     <Form.Control
//                       as="textarea"
//                       rows={3}
//                       placeholder="Enter content here"
//                       value={item.content}
//                       onChange={(e) =>
//                         handleSubContentChange(index, "content", e.target.value)
//                       }
//                     />
//                   </Form.Group>
//                 </Col>
//                 <Col md={1} className="text-end">
//                   <Button
//                     variant="danger"
//                     size="lg"
//                     onClick={() => handleDeleteSubContent(index)}
//                   >
//                     <i className="fas fa-trash-alt"></i>
//                   </Button>
//                 </Col>
//               </Row>
//             </Card.Body>
//           </Card>
//         ))}

//         <div className="d-flex gap-2">
//           <Button
//             variant="outline-primary"
//             onClick={() => handleAddSubContent("OnlyParagraph")}
//           >
//             + Add Only Paragraph
//           </Button>
//           <Button
//             variant="outline-primary"
//             onClick={() => handleAddSubContent("ParagraphWithTitle")}
//           >
//             + Add Paragraph with Title
//           </Button>
//         </div>
//       </Form>
//     </div>
//   );
// };

// export default BlogContentStep;

// import React, { useState } from "react";
// import { Form, Button, Card, Row, Col } from "react-bootstrap";
// import ReactQuill from "react-quill";
// import DOMPurify from "dompurify"; // Import DOMPurify
// import "react-quill/dist/quill.snow.css"; // Import Quill styles
// import "bootstrap/dist/css/bootstrap.min.css";

// const BlogContentStep = ({ blogData, onUpdate }) => {
//   const [subContent, setSubContent] = useState(blogData.subContent || []);

//   // Add new subcontent (either paragraph only or paragraph with title)
//   const handleAddSubContent = (type) => {
//     const newSubContent =
//       type === "OnlyParagraph"
//         ? { type: "OnlyParagraph", content: "" }
//         : { type: "ParagraphWithTitle", title: "", content: "" };

//     setSubContent([...subContent, newSubContent]);
//     onUpdate({ ...blogData, subContent: [...subContent, newSubContent] });
//   };

//   // Handle changes in content or title for sub-content items
//   const handleSubContentChange = (index, field, value) => {
//     const updatedSubContent = [...subContent];
//     if (field === "title") {
//       updatedSubContent[index].title = value;
//     } else if (field === "content") {
//       updatedSubContent[index].content = value; // No sanitization here as we're sanitizing during rendering
//     }
//     setSubContent(updatedSubContent);
//     onUpdate({ ...blogData, subContent: updatedSubContent });
//   };

//   // Delete sub-content item
//   const handleDeleteSubContent = (index) => {
//     const updatedSubContent = subContent.filter((_, i) => i !== index);
//     setSubContent(updatedSubContent);
//     onUpdate({ ...blogData, subContent: updatedSubContent });
//   };

//   return (
//     <div className="mt-4">
//       <h3>Create Blog</h3>
//       <Form>
//         <Form.Group className="mb-3">
//           <Form.Label>Section</Form.Label>
//           <Form.Select
//             value={blogData.section}
//             onChange={(e) => onUpdate({ ...blogData, section: e.target.value })}
//           >
//             <option value="">Select Section</option>
//             <option value="case_studies">Case Studies</option>
//             <option value="blogs">Blogs</option>
//           </Form.Select>
//         </Form.Group>

//         <Form.Group className="mb-3">
//           <Form.Label>Main Title</Form.Label>
//           <Form.Control
//             type="text"
//             placeholder="Enter main title"
//             value={blogData.mainTitle}
//             onChange={(e) => onUpdate({ ...blogData, mainTitle: e.target.value })}
//           />
//         </Form.Group>

//         <h4>Sub Content</h4>
//         {subContent.map((item, index) => (
//           <Card key={index} className="mb-3 shadow-sm">
//             <Card.Body>
//               <Row className="align-items-center">
//                 <Col md={11}>
//                   {item.type === "ParagraphWithTitle" && (
//                     <Form.Group className="mb-2">
//                       <Form.Label>Paragraph Title</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Sub Title"
//                         value={item.title}
//                         onChange={(e) =>
//                           handleSubContentChange(index, "title", e.target.value)
//                         }
//                       />
//                     </Form.Group>
//                   )}
//                   <Form.Group>
//                     <Form.Label>Paragraph</Form.Label>
//                     <ReactQuill
//                       theme="snow"
//                       value={item.content}
//                       onChange={(value) =>
//                         handleSubContentChange(index, "content", value)
//                       }
//                       placeholder="Enter content here"
//                     />
//                   </Form.Group>

//                   {/* Display sanitized content using dangerouslySetInnerHTML */}
//                   <div
//                     className="mt-2"
//                     dangerouslySetInnerHTML={{
//                       __html: DOMPurify.sanitize(item.content),
//                     }}
//                   />
//                 </Col>
//                 <Col md={1} className="text-end">
//                   <Button
//                     variant="danger"
//                     size="lg"
//                     onClick={() => handleDeleteSubContent(index)}
//                   >
//                     <i className="fas fa-trash-alt"></i>
//                   </Button>
//                 </Col>
//               </Row>
//             </Card.Body>
//           </Card>
//         ))}

//         <div className="d-flex gap-2">
//           <Button
//             variant="outline-primary"
//             onClick={() => handleAddSubContent("OnlyParagraph")}
//           >
//             + Add Only Paragraph
//           </Button>
//           <Button
//             variant="outline-primary"
//             onClick={() => handleAddSubContent("ParagraphWithTitle")}
//           >
//             + Add Paragraph with Title
//           </Button>
//         </div>
//       </Form>
//     </div>
//   );
// };

// export default BlogContentStep;


// import React, { useState } from "react";
// import { Form, Button, Card, Row, Col } from "react-bootstrap";
// import ReactQuill from "react-quill";
// import DOMPurify from "dompurify"; // Import DOMPurify
// import "react-quill/dist/quill.snow.css"; // Import Quill styles
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./blog_stepper.css"

// // Add custom font sizes in pixels
// const Size = ReactQuill.Quill.import("formats/size");
// Size.whitelist = ["12px", "14px", "16px", "18px", "20px", "24px", "36px"];
// ReactQuill.Quill.register(Size, true);

// // Add font families and sizes
// const Font = ReactQuill.Quill.import("formats/font");
// Font.whitelist = ["Arial", "Roboto", "Times-New-Roman", "Courier-New", "Georgia"];
// ReactQuill.Quill.register(Font, true);

// const BlogContentStep = ({ blogData, onUpdate }) => {
//   const [subContent, setSubContent] = useState(blogData.subContent || []);

//   // Toolbar options for ReactQuill
//   const customModules = {
//     toolbar: [
//       // Dropdown for heading levels
//       [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6, false] }, 
//       // { font: [] }
//       { font: Font.whitelist }, 
    
//     ],
//       // Font size
//       // [{ size: [] }],
//       [{ size: Size.whitelist }], // Custom pixel sizes
//       // Text formatting tools
//       ["bold", "italic", "underline", "strike"], 
//       // Alignment tools
//       [{ align: [] }],
//       // Subscript and Superscript
//       ["subscript", "superscript"],
//       // Lists
//       [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
//       // Code block and quotes
//       ["blockquote", "code-block"],
//       // Color tools
//       [{ color: [] }, { background: [] }],
//       // Embedding links, images, videos
//       ["link", "image", "video"],
//       // Direction (LTR/RTL)
//       [{ direction: "rtl" }],
//       // Clear formatting
//       ["clean"],
//     ],
//   };

//   const customFormats = [
//     "header",
//     "font",
//     "size",
//     "bold",
//     "italic",
//     "underline",
//     "strike",
//     "align",
//     "subscript",
//     "superscript",
//     "list",
//     "bullet",
//     "indent",
//     "blockquote",
//     "code-block",
//     "color",
//     "background",
//     "link",
//     "image",
//     "video",
//     "direction",
//   ];

//   // Functionality as described in the initial code
//   const handleAddSubContent = (type) => {
//     const newSubContent =
//       type === "OnlyParagraph"
//         ? { type: "OnlyParagraph", content: "" }
//         : { type: "ParagraphWithTitle", title: "", content: "" };

//     setSubContent([...subContent, newSubContent]);
//     onUpdate({ ...blogData, subContent: [...subContent, newSubContent] });
//   };

//   const handleSubContentChange = (index, field, value) => {
//     const updatedSubContent = [...subContent];
//     if (field === "title") {
//       updatedSubContent[index].title = value;
//     } else if (field === "content") {
//       updatedSubContent[index].content = value;
//     }
//     setSubContent(updatedSubContent);
//     onUpdate({ ...blogData, subContent: updatedSubContent });
//   };

//   const handleDeleteSubContent = (index) => {
//     const updatedSubContent = subContent.filter((_, i) => i !== index);
//     setSubContent(updatedSubContent);
//     onUpdate({ ...blogData, subContent: updatedSubContent });
//   };

//   return (
//     <div className="mt-4">
//       <h3>Create Blog</h3>
//       <Form>
//         <Form.Group className="mb-3">
//           <Form.Label>Section</Form.Label>
//           <Form.Select
//             value={blogData.section}
//             onChange={(e) => onUpdate({ ...blogData, section: e.target.value })}
//           >
//             <option value="">Select Section</option>
//             <option value="case_studies">Case Studies</option>
//             <option value="blogs">Blogs</option>
//           </Form.Select>
//         </Form.Group>

//         <Form.Group className="mb-3">
//           <Form.Label>Main Title</Form.Label>
//           <Form.Control
//             type="text"
//             placeholder="Enter main title"
//             value={blogData.mainTitle}
//             onChange={(e) => onUpdate({ ...blogData, mainTitle: e.target.value })}
//           />
//         </Form.Group>

//         <h4>Sub Content</h4>
//         {subContent.map((item, index) => (
//           <Card key={index} className="mb-3 shadow-sm">
//             <Card.Body>
//               <Row className="align-items-center">
//                 <Col md={11}>
//                   {item.type === "ParagraphWithTitle" && (
//                     <Form.Group className="mb-2">
//                       <Form.Label>Paragraph Title</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Sub Title"
//                         value={item.title}
//                         onChange={(e) =>
//                           handleSubContentChange(index, "title", e.target.value)
//                         }
//                       />
//                     </Form.Group>
//                   )}
//                   <Form.Group>
//                     <Form.Label>Paragraph</Form.Label>
//                     <ReactQuill
//                       theme="snow"
//                       value={item.content}
//                       onChange={(value) =>
//                         handleSubContentChange(index, "content", value)
//                       }
//                       placeholder="Enter content here"
//                       modules={customModules}
//                       formats={customFormats}
//                     />
//                   </Form.Group>

//                   {/* Display sanitized content using dangerouslySetInnerHTML */}
//                   <div
//                     className="mt-2"
//                     dangerouslySetInnerHTML={{
//                       __html: DOMPurify.sanitize(item.content),
//                     }}
//                   />
//                 </Col>
//                 <Col md={1} className="text-end">
//                   <Button
//                     variant="danger"
//                     size="lg"
//                     onClick={() => handleDeleteSubContent(index)}
//                   >
//                     <i className="fas fa-trash-alt"></i>
//                   </Button>
//                 </Col>
//               </Row>
//             </Card.Body>
//           </Card>
//         ))}

//         <div className="d-flex gap-2">
//           <Button
//             variant="outline-primary"
//             onClick={() => handleAddSubContent("OnlyParagraph")}
//           >
//             + Add Only Paragraph
//           </Button>
//           {/* <Button
//             variant="outline-primary"
//             onClick={() => handleAddSubContent("ParagraphWithTitle")}
//           >
//             + Add Paragraph with Title
//           </Button> */}
//         </div>
//       </Form>
//     </div>
//   );
// };

// export default BlogContentStep;


import React, { useState, useEffect } from "react";
import { Form, Card, Row, Col } from "react-bootstrap";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import "react-quill/dist/quill.snow.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./blog_stepper.css";

// Add custom font sizes in pixels
const Size = ReactQuill.Quill.import("formats/size");
Size.whitelist = ["12px", "14px", "16px", "18px", "20px", "24px", "36px"];
ReactQuill.Quill.register(Size, true);

// Add font families
const Font = ReactQuill.Quill.import("formats/font");
Font.whitelist = ["Arial", "Roboto", "Times-New-Roman", "Courier-New", "Georgia"];
ReactQuill.Quill.register(Font, true);

const BlogContentStep = ({ blogData, onUpdate }) => {
  // Ensure at least one paragraph is loaded
  const defaultSubContent = [
    { type: "OnlyParagraph", content: "" },
  ];

  const [subContent, setSubContent] = useState(blogData.subContent || defaultSubContent);

  useEffect(() => {
    // Check and ensure there's always at least one paragraph box
    if (subContent.length === 0) {
      setSubContent(defaultSubContent);
      onUpdate({ ...blogData, subContent: defaultSubContent });
    }
  }, [subContent, onUpdate, blogData]);

  const customModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6, false] }, { font: Font.whitelist }],
      [{ size: Size.whitelist }],
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      ["subscript", "superscript"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["blockquote", "code-block"],
      [{ color: [] }, { background: [] }],
      ["link", "image", "video"],
      [{ direction: "rtl" }],
      ["clean"],
    ],
  };

  const customFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "align",
    "subscript",
    "superscript",
    "list",
    "bullet",
    "indent",
    "blockquote",
    "code-block",
    "color",
    "background",
    "link",
    "image",
    "video",
    "direction",
  ];

  const handleSubContentChange = (index, field, value) => {
    const updatedSubContent = [...subContent];
    if (field === "title") {
      updatedSubContent[index].title = value;
    } else if (field === "content") {
      updatedSubContent[index].content = value;
    }
    setSubContent(updatedSubContent);
    onUpdate({ ...blogData, subContent: updatedSubContent });
  };

  return (
    <div className="mt-4">
      <h3>Create Blog</h3>
      <Form>
        {/* Section Selection */}
        <Form.Group className="mb-3">
          <Form.Label>Section</Form.Label>
          <Form.Select
            value={blogData.section}
            onChange={(e) => onUpdate({ ...blogData, section: e.target.value })}
          >
            <option value="">Select Section</option>
            <option value="case_studies">Case Studies</option>
            <option value="blogs">Blogs</option>
          </Form.Select>
        </Form.Group>

        {/* Main Title */}
        <Form.Group className="mb-3">
          <Form.Label>Main Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter main title"
            value={blogData.mainTitle}
            onChange={(e) => onUpdate({ ...blogData, mainTitle: e.target.value })}
          />
        </Form.Group>

        {/* Sub Content */}
        <h4>Sub Content</h4>
        {subContent.map((item, index) => (
          <Card key={index} className="mb-3 shadow-sm">
            <Card.Body>
              <Row className="align-items-center">
                <Col md={12}>
                  {item.type === "ParagraphWithTitle" && (
                    <Form.Group className="mb-2">
                      <Form.Label>Paragraph Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Sub Title"
                        value={item.title}
                        onChange={(e) =>
                          handleSubContentChange(index, "title", e.target.value)
                        }
                      />
                    </Form.Group>
                  )}
                  <Form.Group>
                    <Form.Label>Paragraph</Form.Label>
                    <ReactQuill
                      theme="snow"
                      value={item.content}
                      onChange={(value) =>
                        handleSubContentChange(index, "content", value)
                      }
                      placeholder="Enter content here"
                      modules={customModules}
                      formats={customFormats}
                    />
                    {/* Display sanitized content */}
                    <div
                      className="mt-2"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(item.content),
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}
      </Form>
    </div>
  );
};

export default BlogContentStep;
