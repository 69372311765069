import "../extraPages/hcm/hcm.css";
import Contact_us from "../contact_us/contact_us";
import Topbar from "../topbar/topbar";
import BlogIndiView from "./blog_indi_view";
// import TopBar from "../topbar/topbar";
import Footer from "../Footer/Footer";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export default function BlogIndiViewMain({ openModal }) {
  const solutionsAndServicesRef = useRef(null);
  const location = useLocation();

  // useEffect(() => {
  //   // Scroll to the solutionsAndServicesRef element if it exists
  //   if (solutionsAndServicesRef.current) {
  //     solutionsAndServicesRef.current.scrollIntoView({ behavior: "auto" });
  //   }
  // }, [location]);
  useEffect(() => {
    // Scroll to top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  

  const handleGoBack = () => {
    window.history.back(); // Go back in the browser's history
  };
  return (
    <div id="blogIndiView/:id">
      <Topbar />
      <BlogIndiView />
      <Footer />
    </div>
  );
}
