// import React from "react";
// import "./blogs.css";

// import image1 from "../../img/case_study_1.png";
// import image2 from "../../img/case_study_2.png";
// import image3 from "../../img/case_study_3.png";

// const Blogs = () => {
//   const blogs = [
//     {
//       title: "Title of the blog goes here",
//       description: "Lorem ipsum is a placeholder text commonly used to demonstrate the visual",
//       img: image1, // Replace with your actual image paths
//     },
//     {
//       title: "Title of the blog goes here",
//       description: "Lorem ipsum is a placeholder text commonly used to demonstrate the visual",
//       img: image2,
//     },
//     {
//       title: "Title of the blog goes here",
//       description: "Lorem ipsum is a placeholder text commonly used to demonstrate the visual",
//       img: image3,
//     },
//     {
//       title: "Title of the blog goes here",
//       description: "Lorem ipsum is a placeholder text commonly used to demonstrate the visual",
//       img: image1,
//     },
//     {
//       title: "Title of the blog goes here",
//       description: "Lorem ipsum is a placeholder text commonly used to demonstrate the visual",
//       img: image2,
//     },
//     {
//       title: "Title of the blog goes here",
//       description: "Lorem ipsum is a placeholder text commonly used to demonstrate the visual",
//       img: image3,
//     },
//   ];

//   return (
//     <div className="blogs-container-b">
//       <h2 className="section-title-b">Blogs</h2>
//       <div className="blogs-grid-b">
//         {blogs.map((blog, index) => (
//           <div key={index} className="blog-card-b">
//             <img src={blog.img} alt={blog.title} className="blog-image-b" />
//             <div className="blog-content-b">
//               <h3 className="blog-title-b">{blog.title}</h3>
//               <p className="blog-description-b">{blog.description}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//       <div className="more-button-container-b">
//         <button className="more-button-b">
//           <span className="dropdown-icon-b">
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="16"
//               height="16"
//               fill="currentColor"
//               viewBox="0 0 16 16"
//             >
//               <path d="M1.5 5.5l6 6 6-6h-12z" />
//             </svg>
//           </span>
//         </button>
//       </div>
//     </div>
//   );
// };

// // export default Blogs;
// import React, { useState, useEffect } from "react";
// import "./blogs.css";
// import {
//   collection,
//   query,
//   where,
//   getDocs,
//   limit,
//   startAfter,
// } from "firebase/firestore";
// import { db } from "../../firebase"; // Import Firebase configuration

// import image1 from "../../img/case_study_1.png";
// import image2 from "../../img/case_study_2.png";
// import image3 from "../../img/case_study_3.png";

// const randomImages = [
//   image1,
//   image2,
//   image3,
//   image1,
//   image2,
//   image3,
//   image1,
//   image2,
//   image3,
// ];

// const Blogs = () => {
//   const [blogs, setBlogs] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [hasMore, setHasMore] = useState(true);
//   const [lastDoc, setLastDoc] = useState(null); // Track last document for pagination
//   const [page, setPage] = useState(1); // Current page for data fetching

//   // Function to fetch blogs from Firestore
//   const fetchBlogs = async (page) => {
//     setLoading(true);
//     try {
//       const blogsRef = collection(db, "blogs");

//       // Query to fetch blogs, only use startAfter if lastDoc is available
//       const blogsQuery = query(
//         blogsRef,
//         where("section", "==", "blogs"), // Ensure you have this field in your Firestore
//         limit(3),
//         ...(page > 1 && lastDoc ? [startAfter(lastDoc)] : []) // Apply pagination if lastDoc exists
//       );

//       const querySnapshot = await getDocs(blogsQuery);
//       const newBlogs = [];

//       querySnapshot.forEach((doc) => {
//         const data = doc.data();
//         newBlogs.push({
//           id: doc.id,
//           mainTitle: data.mainTitle || "No Title", // Fallback title if missing
//           content: data.subContent && data.subContent[0]?.content || "No Content", // Fetching the content from subContent[0]
//           img: randomImages[Math.floor(Math.random() * randomImages.length)], // Random image
//         });
//       });

//       if (newBlogs.length > 0) {
//         setBlogs((prev) => [...prev, ...newBlogs]);
//         setHasMore(newBlogs.length === 3); // Check if there might be more data
//         setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]); // Save last document for pagination
//       } else {
//         setHasMore(false); // No more data to fetch
//       }
//     } catch (error) {
//       console.error("Error fetching blogs:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Helper function to limit words in a string
//   const limitWords = (str, wordLimit) => {
//     const words = str.split(" ");
//     return words.length > wordLimit ? words.slice(0, wordLimit).join(" ") + "..." : str;
//   };

//   // Fetch blogs when the page changes or component mounts
//   useEffect(() => {
//     fetchBlogs(page);
//   }, [page]);

//   // Handle Load More button click
//   const handleLoadMore = () => {
//     setPage((prevPage) => prevPage + 1); // Increment page number to fetch next set of blogs
//   };

//   return (
//     <div className="blogs-container-b">
//       <h2 className="section-title-b">Blogs</h2>
//       <div className="blogs-grid-b">
//         {loading
//           ? // Show shimmer effect while loading
//             Array(3)
//               .fill(0)
//               .map((_, index) => (
//                 <div key={index} className="blog-card-b">
//                   <div className="blog-image-b shimmer"></div>
//                   <div className="blog-content-b">
//                     <div className="blog-title-b shimmer"></div>
//                     <div className="blog-description-b shimmer"></div>
//                   </div>
//                 </div>
//               ))
//           : // Show blogs once loaded
//             blogs.map((blog) => (
//               <div key={blog.id} className="blog-card-b">
//                 <img
//                   src={blog.img || "https://via.placeholder.com/500x300?text=Default+Image"} // Use a random image or fallback
//                   alt={blog.mainTitle}
//                   className="blog-image-b"
//                 />
//                 <div className="blog-content-b">
//                   <h3 className="blog-title-b">
//                     {limitWords(blog.mainTitle, 7)} {/* Limit main title to 7 words */}
//                   </h3>
//                   <p className="blog-description-b">
//                     {limitWords(blog.content, 8)} {/* Limit content to 8 words */}
//                   </p>
//                 </div>
//               </div>
//             ))}
//       </div>

//       {/* Load More Button */}
//       {hasMore && !loading && (
//         <div className="more-button-container-b">
//           <button className="more-button-b" onClick={handleLoadMore}>
//             Load More
//             <span className="dropdown-icon-b">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="16"
//                 height="16"
//                 fill="currentColor"
//                 viewBox="0 0 16 16"
//               >
//                 <path d="M1.5 5.5l6 6 6-6h-12z" />
//               </svg>
//             </span>
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Blogs;

// import React, { useState, useEffect } from "react";
// import "./blogs.css";
// import {
//   collection,
//   query,
//   where,
//   getDocs,
//   limit,
//   startAfter,
// } from "firebase/firestore";
// import { db } from "../../firebase";
// import { useNavigate } from "react-router-dom";

// import image1 from "../../img/case_study_1.png";
// import image2 from "../../img/case_study_2.png";
// import image3 from "../../img/case_study_3.png";

// const randomImages = [
//   image1,
//   image2,
//   image3,
//   image1,
//   image2,
//   image3,
//   image1,
//   image2,
//   image3,
// ];

// const Blogs = () => {
//   const [blogs, setBlogs] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [hasMore, setHasMore] = useState(true);
//   const [lastDoc, setLastDoc] = useState(null);
//   const [page, setPage] = useState(1);
//   const navigate = useNavigate();

//   // Function to fetch blogs from Firestore
//   const fetchBlogs = async (page) => {
//     setLoading(true);
//     try {
//       const blogsRef = collection(db, "blogs");

//       // Query to fetch blogs with different limits for initial and subsequent pages
//       const blogsQuery = query(
//         blogsRef,
//         where("section", "==", "blogs"),
//         limit(page === 1 ? 6 : 3), // Fetch 6 blogs initially, then 3 for pagination
//         ...(page > 1 && lastDoc ? [startAfter(lastDoc)] : [])
//       );

//       const querySnapshot = await getDocs(blogsQuery);
//       const newBlogs = [];

//       querySnapshot.forEach((doc) => {
//         const data = doc.data();
//         newBlogs.push({
//           id: doc.id,
//           mainTitle: data.mainTitle || "No Title",
//           content: data.subContent && data.subContent[0]?.content || "No Content",
//           img: randomImages[Math.floor(Math.random() * randomImages.length)],
//         });
//       });

//       if (newBlogs.length > 0) {
//         setBlogs((prev) => [...prev, ...newBlogs]);
//         setHasMore(newBlogs.length === (page === 1 ? 6 : 3)); // Check if more blogs exist
//         setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
//       } else {
//         setHasMore(false);
//       }
//     } catch (error) {
//       console.error("Error fetching blogs:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleBlogClick = (id) => {
//     navigate(`/blogIndiView/${id}`); // Navigate to the detailed view
//   };

//   // Helper function to limit words in a string
//   const limitWords = (str, wordLimit) => {
//     const words = str.split(" ");
//     return words.length > wordLimit ? words.slice(0, wordLimit).join(" ") + "..." : str;
//   };

//   // Fetch blogs on component mount and when page changes
//   useEffect(() => {
//     fetchBlogs(page);
//   }, [page]);

//   // Handle Load More button click
//   const handleLoadMore = () => {
//     setPage((prevPage) => prevPage + 1);
//   };

//   return (
//     <div className="blogs-container-b">
//       <h2 className="section-title-b">Blogs</h2>
//       <div className="blogs-grid-b">
//         {loading
//           ? // Shimmer effect for loading
//             Array(6)
//               .fill(0)
//               .map((_, index) => (
//                 <div key={index} className="blog-card-b">
//                   <div className="blog-image-b shimmer"></div>
//                   <div className="blog-content-b">
//                     <div className="blog-title-b shimmer"></div>
//                     <div className="blog-description-b shimmer"></div>
//                   </div>
//                 </div>
//               ))
//           : // Render blogs
//             blogs.map((blog) => (
//               <div key={blog.id} className="blog-card-b" onClick={() => handleBlogClick(blog.id)}>
//                 <img
//                   src={blog.img || "https://via.placeholder.com/500x300?text=Default+Image"}
//                   alt={blog.mainTitle}
//                   className="blog-image-b"
//                 />
//                 <div className="blog-content-b">
//                   <h3 className="blog-title-b">
//                     {limitWords(blog.mainTitle, 7)}
//                   </h3>
//                   <p className="blog-description-b">
//                     {limitWords(blog.content, 8)}
//                   </p>
//                 </div>
//               </div>
//             ))}
//       </div>

//       {/* Load More Button */}
//       {hasMore && !loading && (
//         <div className="more-button-container-b">
//           <button className="more-button-b" onClick={handleLoadMore}>
//             Load More
//             <span className="dropdown-icon-b">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="16"
//                 height="16"
//                 fill="currentColor"
//                 viewBox="0 0 16 16"
//               >
//                 <path d="M1.5 5.5l6 6 6-6h-12z" />
//               </svg>
//             </span>
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Blogs;

// import React, { useState, useEffect } from "react";
// import "./blogs.css";
// import {
//   collection,
//   query,
//   where,
//   getDocs,
//   limit,
//   startAfter,
//   updateDoc,
//   increment,
//   doc,getDoc,setDoc
// } from "firebase/firestore";
// import { db } from "../../firebase";
// import { useNavigate } from "react-router-dom";
// import DOMPurify from "dompurify"; // Import DOMPurify

// import image1 from "../../img/case_study_1.png";
// import image2 from "../../img/case_study_2.png";
// import image3 from "../../img/case_study_3.png";
// import { v4 as uuidv4 } from "uuid";

// const randomImages = [
//   image1,
//   image2,
//   image3,
//   image1,
//   image2,
//   image3,
//   image1,
//   image2,
//   image3,
// ];

// const Blogs = () => {
//   const [blogs, setBlogs] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [hasMore, setHasMore] = useState(true);
//   const [lastDoc, setLastDoc] = useState(null);
//   const [page, setPage] = useState(1);
//   const navigate = useNavigate();

// const getUserId = () => {
//   let userId = localStorage.getItem("userId");
//   if (!userId) {
//     userId = uuidv4(); // Generate a new unique ID
//     localStorage.setItem("userId", userId);
//   }
//   return userId;
// };

//   // Function to fetch blogs from Firestore
//   const fetchBlogs = async (page) => {
//     setLoading(true);
//     try {
//       const blogsRef = collection(db, "blogs");

//       // Query to fetch blogs with different limits for initial and subsequent pages
//       const blogsQuery = query(
//         blogsRef,
//         where("section", "==", "blogs"),
//         limit(page === 1 ? 6 : 3), // Fetch 6 blogs initially, then 3 for pagination
//         ...(page > 1 && lastDoc ? [startAfter(lastDoc)] : [])
//       );

//       const querySnapshot = await getDocs(blogsQuery);
//       const newBlogs = [];

//       querySnapshot.forEach((doc) => {
//         const data = doc.data();
//         newBlogs.push({
//           id: doc.id,
//           mainTitle: data.mainTitle || "No Title",
//           content: data.subContent && data.subContent[0]?.content || "No Content",
//           img: randomImages[Math.floor(Math.random() * randomImages.length)],
//         });
//       });

//       if (newBlogs.length > 0) {
//         setBlogs((prev) => [...prev, ...newBlogs]);
//         setHasMore(newBlogs.length === (page === 1 ? 6 : 3)); // Check if more blogs exist
//         setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
//       } else {
//         setHasMore(false);
//       }
//     } catch (error) {
//       console.error("Error fetching blogs:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // const handleBlogClick = (id) => {
//   //   navigate(`/blogIndiView/${id}`); // Navigate to the detailed view
//   // };

//   const handleBlogClick = async (blogId) => {
//     const userId = getUserId(); // Get unique user identifier
//     const viewedBlogs = JSON.parse(localStorage.getItem("viewedBlogs")) || {};

//     // Check if the user has already viewed this blog
//     if (!viewedBlogs[blogId]) {
//       try {
//         const blogRef = doc(db, "blogs", blogId);

//         // Fetch the blog document
//         const blogDoc = await getDoc(blogRef);

//         if (blogDoc.exists()) {
//           // If viewCount doesn't exist, set it to 0 initially, then increment it
//           const currentViewCount = blogDoc.data().viewCount || 0;

//           // Use merge: true to ensure other fields stay intact
//           await updateDoc(blogRef, {
//             viewCount: increment(1), // Increment or initialize to 1 if missing
//           });
//           console.log("updated view count success");
//         } else {
//           // If the document doesn't exist, initialize with a viewCount of 1
//           await setDoc(blogRef, { viewCount: 1 }, { merge: true });
//         }

//         // Mark blog as viewed locally
//         viewedBlogs[blogId] = true;
//         localStorage.setItem("viewedBlogs", JSON.stringify(viewedBlogs));
//         console.log("updated view count");
//       } catch (error) {
//         console.error("Error updating view count:", error);
//       }
//     }

//     // Navigate to the blog's detailed view
//     navigate(`/blogIndiView/${blogId}`);
//   };

//   // Helper function to limit words in a string
//   const limitWords = (str, wordLimit) => {
//     const words = str.split(" ");
//     return words.length > wordLimit ? words.slice(0, wordLimit).join(" ") + "..." : str;
//   };

//   // Fetch blogs on component mount and when page changes
//   useEffect(() => {
//     fetchBlogs(page);
//   }, [page]);

//   // Handle Load More button click
//   const handleLoadMore = () => {
//     setPage((prevPage) => prevPage + 1);
//   };

//   return (
//     <div className="blogs-container-b">
//       <h2 className="section-title-b">Blogs</h2>
//       <div className="blogs-grid-b">
//         {loading
//           ? // Shimmer effect for loading
//             Array(6)
//               .fill(0)
//               .map((_, index) => (
//                 <div key={index} className="blog-card-b">
//                   <div className="blog-image-b shimmer"></div>
//                   <div className="blog-content-b">
//                     <div className="blog-title-b shimmer"></div>
//                     <div className="blog-description-b shimmer"></div>
//                   </div>
//                 </div>
//               ))
//           : // Render blogs
//             blogs.map((blog) => (
//               <div key={blog.id} className="blog-card-b" onClick={() => handleBlogClick(blog.id)}>
//                 <img
//                   src={blog.img || "https://via.placeholder.com/500x300?text=Default+Image"}
//                   alt={blog.mainTitle}
//                   className="blog-image-b"
//                 />
//                 <div className="blog-content-b">
//                   <h3 className="blog-title-b">
//                     {limitWords(blog.mainTitle, 7)}
//                   </h3>
//                   {/* Use dangerouslySetInnerHTML for sanitized HTML */}
//                   <p
//                     className="blog-description-b"
//                     dangerouslySetInnerHTML={{
//                       __html: DOMPurify.sanitize(limitWords(blog.content, 8)),
//                     }}
//                   />
//                 </div>
//               </div>
//             ))}
//       </div>

//       {/* Load More Button */}
//       {hasMore && !loading && (
//         <div className="more-button-container-b">
//           <button className="more-button-b" onClick={handleLoadMore}>
//             Load More
//             <span className="dropdown-icon-b">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="16"
//                 height="16"
//                 fill="currentColor"
//                 viewBox="0 0 16 16"
//               >
//                 <path d="M1.5 5.5l6 6 6-6h-12z" />
//               </svg>
//             </span>
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Blogs;

// import React, { useState, useEffect } from "react";
// import Skeleton from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css"; // Skeleton styles
// import "./blogs.css";
// import {
//   collection,
//   query,
//   where,
//   getDocs,
//   limit,
//   startAfter,
//   updateDoc,
//   increment,
//   doc,
//   getDoc,
//   setDoc,
// } from "firebase/firestore";
// import { db } from "../../firebase";
// import { useNavigate } from "react-router-dom";
// import DOMPurify from "dompurify";
// import image1 from "../../img/case_study_1.png";
// import image2 from "../../img/case_study_2.png";
// import image3 from "../../img/case_study_3.png";
// import { v4 as uuidv4 } from "uuid";

// const randomImages = [image1, image2, image3];

// const Blogs = () => {
//   const [blogs, setBlogs] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [hasMore, setHasMore] = useState(true);
//   const [lastDoc, setLastDoc] = useState(null);
//   const [page, setPage] = useState(1);
//   const navigate = useNavigate();

//   const getUserId = () => {
//     let userId = localStorage.getItem("userId");
//     if (!userId) {
//       userId = uuidv4();
//       localStorage.setItem("userId", userId);
//     }
//     return userId;
//   };

//   // const fetchBlogs = async (page) => {
//   //   setLoading(true);
//   //   try {
//   //     const blogsRef = collection(db, "blogs");
//   //     const blogsQuery = query(
//   //       blogsRef,
//   //       where("section", "==", "blogs"),
//   //       limit(page === 1 ? 6 : 3),
//   //       ...(page > 1 && lastDoc ? [startAfter(lastDoc)] : [])
//   //     );

//   //     const querySnapshot = await getDocs(blogsQuery);
//   //     const newBlogs = [];

//   //     querySnapshot.forEach((doc) => {
//   //       const data = doc.data();
//   //       newBlogs.push({
//   //         id: doc.id,
//   //         mainTitle: data.mainTitle || "No Title",
//   //         content: data.subContent?.[0]?.content || "No Content",
//   //         img: randomImages[Math.floor(Math.random() * randomImages.length)],
//   //       });
//   //     });

//   //     if (newBlogs.length > 0) {
//   //       setBlogs((prev) => [...prev, ...newBlogs]);
//   //       setHasMore(newBlogs.length === (page === 1 ? 6 : 3));
//   //       setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
//   //     } else {
//   //       setHasMore(false);
//   //     }
//   //   } catch (error) {
//   //     console.error("Error fetching blogs:", error);
//   //   } finally {
//   //     setLoading(false);
//   //   }
//   // };

//   const fetchBlogs = async (page) => {
//     setLoading(true);
//     try {
//       const blogsRef = collection(db, "blogs");
  
//       // Fetch the data from Firestore without 'orderBy'
//       const queryConstraints = [
//         where("section", "==", "blogs"),
//         limit(page === 1 ? 6 : 3),
//       ];
  
//       if (page > 1 && lastDoc) {
//         queryConstraints.push(startAfter(lastDoc)); // Pagination
//       }
  
//       const blogsQuery = query(blogsRef, ...queryConstraints);
//       const querySnapshot = await getDocs(blogsQuery);
  
//       const fetchedBlogs = [];
//       querySnapshot.forEach((doc) => {
//         const data = doc.data();
//         fetchedBlogs.push({
//           id: doc.id,
//           mainTitle: data.mainTitle || "No Title",
//           content: data.subContent?.[0]?.content || "No Content",
//           img: randomImages[Math.floor(Math.random() * randomImages.length)],
//           createdAt: data.createdAt,
//           updatedAt: data.updatedAt,
//         });
//       });
  
//       // Sort locally by `updatedAt` and `createdAt` in descending order
//       const sortedBlogs = fetchedBlogs.sort((a, b) => {
//         const dateA = b.updatedAt?.seconds || 0;
//         const dateB = a.updatedAt?.seconds || 0;
  
//         if (dateA === dateB) {
//           // If updatedAt values are equal, sort by createdAt
//           return (b.createdAt?.seconds || 0) - (a.createdAt?.seconds || 0);
//         }
//         return dateA - dateB;
//       });
  
//       if (sortedBlogs.length > 0) {
//         setBlogs((prev) => [...prev, ...sortedBlogs]);
//         setHasMore(sortedBlogs.length === (page === 1 ? 6 : 3));
//         setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
//       } else {
//         setHasMore(false);
//       }
//     } catch (error) {
//       console.error("Error fetching blogs:", error);
//     } finally {
//       setLoading(false);
//     }
//   };
  

//   const handleBlogClick = async (blogId) => {
//     const userId = getUserId();
//     const viewedBlogs = JSON.parse(localStorage.getItem("viewedBlogs")) || {};

//     if (!viewedBlogs[blogId]) {
//       try {
//         const blogRef = doc(db, "blogs", blogId);
//         const blogDoc = await getDoc(blogRef);

//         if (blogDoc.exists()) {
//           await updateDoc(blogRef, {
//             viewCount: increment(1),
//           });
//         } else {
//           await setDoc(blogRef, { viewCount: 1 }, { merge: true });
//         }

//         viewedBlogs[blogId] = true;
//         localStorage.setItem("viewedBlogs", JSON.stringify(viewedBlogs));
//       } catch (error) {
//         console.error("Error updating view count:", error);
//       }
//     }

//     navigate(`/blogIndiView/${blogId}`);
//   };

//   useEffect(() => {
//     fetchBlogs(page);
//   }, [page]);

//   const handleLoadMore = () => {
//     setPage((prevPage) => prevPage + 1);
//   };

//   const limitWords = (str, wordLimit) => {
//     const words = str.split(" ");
//     return words.length > wordLimit
//       ? words.slice(0, wordLimit).join(" ") + "..."
//       : str;
//   };

//   return (
//     <div className="blogs-container-b">
//       <h2 className="section-title-b">Blogs</h2>
//       <div className="blogs-grid-b">
//         {loading && !blogs.length
//           ? Array(6)
//               .fill(0)
//               .map((_, index) => (
//                 <div key={index} className="blog-card-b">
//                   <Skeleton
//                     height={200}
//                     width="100%"
//                     className="blog-image-b"
//                   />
//                   <div className="blog-content-b">
//                     <Skeleton height={20} width="70%" />
//                     <Skeleton height={14} width="90%" count={2} />
//                   </div>
//                 </div>
//               ))
//           : blogs.map((blog) => (
//               <div
//                 key={blog.id}
//                 className="blog-card-b"
//                 onClick={() => handleBlogClick(blog.id)}
//               >
//                 <img
//                   src={
//                     blog.img ||
//                     "https://via.placeholder.com/500x300?text=Default+Image"
//                   }
//                   alt={blog.mainTitle}
//                   className="blog-image-b"
//                 />
//                 <div className="blog-content-b">
//                   <h3 className="blog-title-b">
//                     {limitWords(blog.mainTitle, 7)}
//                   </h3>
//                   <p
//                     className="blog-description-b"
//                     dangerouslySetInnerHTML={{
//                       __html: DOMPurify.sanitize(limitWords(blog.content, 8)),
//                     }}
//                   />
//                 </div>
//               </div>
//             ))}
//       </div>
//       {hasMore && !loading && (
//         <div className="more-button-container-b">
//           <button className="more-button-b" onClick={handleLoadMore}>
//             Load More
//             <span className="dropdown-icon-b">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="16"
//                 height="16"
//                 fill="currentColor"
//                 viewBox="0 0 16 16"
//               >
//                 <path d="M1.5 5.5l6 6 6-6h-12z" />
//               </svg>
//             </span>
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Blogs;


import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Skeleton styles
import "./blogs.css";
import {
  collection,
  query,
  where,
  getDocs,
  limit,
  startAfter,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import defaultImage from "../../img/default_image_sm.png"; // Default fallback image
import { v4 as uuidv4 } from "uuid";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [lastDoc, setLastDoc] = useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const getUserId = () => {
    let userId = localStorage.getItem("userId");
    if (!userId) {
      userId = uuidv4();
      localStorage.setItem("userId", userId);
    }
    return userId;
  };

  const fetchBlogs = async (page) => {
    setLoading(true);
    try {
      const blogsRef = collection(db, "blogs");

      // Query setup with pagination
      const queryConstraints = [
        where("section", "==", "blogs"),
        // orderBy("createdAt", "desc"),
        // orderBy("updatedAt", "desc"),
        limit(page === 1 ? 6 : 3),
      ];
      if (page > 1 && lastDoc) {
        queryConstraints.push(startAfter(lastDoc)); // Add pagination cursor
      }

      const blogsQuery = query(blogsRef, ...queryConstraints);
      const querySnapshot = await getDocs(blogsQuery);

      const fetchedBlogs = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        fetchedBlogs.push({
          id: doc.id,
          mainTitle: data.mainTitle || "No Title",
          content: data.subContent?.[0]?.content || "No Content",
          img: data.cardImage || defaultImage, // Use cardImage or fallback
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
        });
      });

      // Sort fetched blogs locally by updatedAt/createdAt descending
      // const sortedBlogs = fetchedBlogs.sort((a, b) => {
      //   const dateA = b.updatedAt?.seconds || 0;
      //   const dateB = a.updatedAt?.seconds || 0;

      //   if (dateA === dateB) {
      //     return (b.createdAt?.seconds || 0) - (a.createdAt?.seconds || 0);
      //   }
      //   return dateA - dateB;
      // });

      // if (sortedBlogs.length > 0) {
      //   setBlogs((prev) => [...prev, ...sortedBlogs]);
      //   setHasMore(sortedBlogs.length === (page === 1 ? 6 : 3));
      //   setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      // } else {
      //   setHasMore(false);
      // }

       // Combine previous blogs with newly fetched blogs and sort them
    // const sortedBlogs = [...blogs, ...fetchedBlogs].sort((a, b) => {
    //   const dateA = b.updatedAt?.seconds || b.createdAt?.seconds || 0;
    //   const dateB = a.updatedAt?.seconds || a.createdAt?.seconds || 0;
    //   return dateA - dateB;
    // });

    // setBlogs(sortedBlogs);
    // setHasMore(querySnapshot.docs.length === (page === 1 ? 6 : 3)); // Update "hasMore"
    // setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1] || null);

    const sortedBlogs = [...blogs, ...fetchedBlogs].sort((a, b) => {
      const dateA = b.createdAt?.seconds || 0;  // Use `createdAt` for descending order
      const dateB = a.createdAt?.seconds || 0;  // Default to 0 if `createdAt` is missing
      return dateA - dateB;  // Descending order
    });
    
    setBlogs(sortedBlogs);
    setHasMore(querySnapshot.docs.length === (page === 1 ? 6 : 3)); // Update "hasMore"
    setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1] || null);
    
    } catch (error) {
      console.error("Error fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBlogClick = (blogId) => {
    navigate(`/blogIndiView/${blogId}`);
  };

  useEffect(() => {
    fetchBlogs(page);
  }, [page]);

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const limitWords = (str, wordLimit) => {
    const words = str.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : str;
  };

  return (
    <div className="blogs-container-b">
      <h2 className="section-title-b">Blogs</h2>
      <div className="blogs-grid-b">
        {loading && !blogs.length
          ? Array(6)
              .fill(0)
              .map((_, index) => (
                <div key={index} className="blog-card-b">
                  <Skeleton
                    height={200}
                    width="100%"
                    className="blog-image-b"
                  />
                  <div className="blog-content-b">
                    <Skeleton height={20} width="70%" />
                    <Skeleton height={14} width="90%" count={2} />
                  </div>
                </div>
              ))
          : blogs.map((blog) => (
              <div
                key={blog.id}
                className="blog-card-b"
                onClick={() => handleBlogClick(blog.id)}
              >
                <img
                  src={blog.img}
                  alt={blog.mainTitle || "Default Blog Image"}
                  className="blog-image-b"
                />
                <div className="blog-content-b">
                  <h3 className="blog-title-b">
                    {limitWords(blog.mainTitle, 7)}
                  </h3>
                  <p
                    className="blog-description-b"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(limitWords(blog.content, 8)),
                    }}
                  />
                </div>
              </div>
            ))}
      </div>
      {hasMore && !loading && (
        <div className="more-button-container-b">
          <button className="more-button-b" onClick={handleLoadMore}>
            Load More
            <span className="dropdown-icon-b">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M1.5 5.5l6 6 6-6h-12z" />
              </svg>
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default Blogs;
