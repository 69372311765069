import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./admin_blog_list.css";
import { db } from "../../firebase"; // Assuming this is the path to your firebase.js file
import { collection, getDocs, deleteDoc, doc, getDoc } from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Import skeleton styles

const AdminBlogList = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch blogs from Firestore
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "blogs"));
        const blogsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort blogs by createdAt in descending order
        const sortedBlogs = blogsData.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

        setBlogs(sortedBlogs);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const handleCreateBlog = () => {
    navigate("/blogCreate"); // Navigate to the blog creation page
  };

  const handleDelete = async (id) => {
    // Confirm delete action
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this blog?"
    );
    if (!isConfirmed) return;

    try {
      // Delete from Firestore
      await deleteDoc(doc(db, "blogs", id));
      // Update local state by filtering out the deleted blog
      setBlogs(blogs.filter((blog) => blog.id !== id));
      console.log("Blog deleted:", id);
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };

  const handleEdit = async (id) => {
    try {
      const blogDoc = await getDoc(doc(db, "blogs", id));
      if (blogDoc.exists()) {
        const blogData = { id: blogDoc.id, ...blogDoc.data() };
        navigate("/editBlog", { state: { blog: blogData } }); // Pass data to the blog creation page
      } else {
        console.error("Blog not found!");
      }
    } catch (error) {
      console.error("Error fetching blog details:", error);
    }
  };

  // Convert Firestore Timestamp to a readable date string
  const formatDate = (timestamp) => {
    if (timestamp instanceof Timestamp) {
      return new Date(timestamp.seconds * 1000).toLocaleDateString();
    }
    return "Invalid Date"; // Fallback for invalid or undefined timestamp
  };

  if (loading) {
    return (
      <div className="container" style={{ minHeight: "100vh" }}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3>Edit Blogs</h3>
          <Button variant="primary" onClick={handleCreateBlog}>
            + Create new
          </Button>
        </div>
        <Skeleton count={5} height={60} style={{ marginBottom: "15px" }} />
        <Skeleton count={5} height={60} style={{ marginBottom: "15px" }} />
        <Skeleton count={5} height={60} style={{ marginBottom: "15px" }} />
      </div>
    );
  }

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>Edit Blogs</h3>
        <Button variant="primary" onClick={handleCreateBlog}>
          + Create new
        </Button>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Section</th>
            <th>Date posted</th>
            <th>Views</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {blogs.map((blog) => (
            <tr key={blog.id}>
              <td>{blog.mainTitle}</td>
              <td>{blog.section}</td>
              <td>{formatDate(blog.createdAt)}</td>
              <td>{blog.viewCount || 0}</td>
              <td>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => handleEdit(blog.id)}
                  className="me-2"
                >
                  ✏️
                </Button>

                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={() => handleDelete(blog.id)}
                >
                  🗑️
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AdminBlogList;
