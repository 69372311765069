// import React, { useState } from "react";
// import "./case_studies.css";

// import image1 from "../../img/case_study_1.png";
// import image2 from "../../img/case_study_2.png";
// import image3 from "../../img/case_study_3.png";

// // CaseStudyCard component
// const CaseStudies = () => {
//   const caseStudies = [
//     {
//       title:
//         "The Future of Contractor Management: Embracing Automation and Flexibility",
//       img: image1, // Replace with actual image paths
//     },
//     {
//       title:
//         "The Future of Contractor Management: Embracing Automation and Flexibility",
//       img: image2,
//     },
//     {
//       title:
//         "The Future of Contractor Management: Embracing Automation and Flexibility",
//       img: image3,
//     },
//   ];

//   return (
//     <div className="case-studies-container">
//       <h2 className="section-title">Case studies</h2>
//       <div className="case-studies-grid">
//         {caseStudies.map((study, index) => (
//           <div key={index} className="case-study-card">
//             <img
//               src={study.img}
//               alt={study.title}
//               className="case-study-image"
//             />
//             <div className="case-study-overlay"></div>
//             <div className="case-study-content">
//               <p>{study.title}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//       <div className="more-button-container">
//         <button className="more-button">
//           <span className="dropdown-icon">
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="25"
//               height="25"
//               fill="currentColor"
//               viewBox="0 0 16 16"
//             >
//               <path d="M1.5 5.5l6 6 6-6h-12z" />
//             </svg>
//           </span>
//         </button>
//       </div>
//     </div>
//   );
// };

// export default CaseStudies;

// import React, { useState, useEffect } from "react";
// import "./case_studies.css";
// import Skeleton from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css"; // Import skeleton styles
// import {
//   collection,
//   query,
//   where,
//   getDocs,
//   limit,
//   startAfter,
//   updateDoc,
//   increment,
//   doc,getDoc,setDoc
// } from "firebase/firestore";
// import { v4 as uuidv4 } from "uuid";

// import { db } from "../../firebase"; // Your Firebase configuration

// import image1 from "../../img/case_study_1.png";
// import image2 from "../../img/case_study_2.png";
// import image3 from "../../img/case_study_3.png";
// import { useNavigate } from "react-router-dom";


// // Random images
// const randomImages = [
//   image1,
//   image2,
//   image3,
//   image1,
//   image2,
//   image3,
//   image1,
//   image2,
//   image3,
// ];

// // CaseStudyCard component
// const CaseStudies = () => {
//   const [caseStudies, setCaseStudies] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [hasMore, setHasMore] = useState(true);
//   const [lastDoc, setLastDoc] = useState(null); // To track the last document for pagination
//   const [page, setPage] = useState(1); // Track the current page of data
//   const navigate = useNavigate();

//   const getUserId = () => {
//     let userId = localStorage.getItem("userId");
//     if (!userId) {
//       userId = uuidv4(); // Generate a new unique ID
//       localStorage.setItem("userId", userId);
//     }
//     return userId;
//   };

//   // Function to fetch case studies from Firestore
//   // const fetchCaseStudies = async (page) => {
//   //   setLoading(true);
//   //   try {
//   //     const caseStudiesRef = collection(db, "blogs");

//   //     // Query to fetch data from Firestore, only use startAfter if lastDoc is available
//   //     const caseStudiesQuery = query(
//   //       caseStudiesRef,
//   //       where("section", "==", "case_studies"), // Adjust based on your Firestore schema
//   //       limit(3),
//   //       ...(page > 1 && lastDoc ? [startAfter(lastDoc)] : []) // Only apply startAfter if lastDoc is available
//   //     );

//   //     const querySnapshot = await getDocs(caseStudiesQuery);
//   //     const newCaseStudies = [];

//   //     querySnapshot.forEach((doc) => {
//   //       newCaseStudies.push({
//   //         id: doc.id,
//   //         mainTitle: doc.data().mainTitle || "No Title", // Add default if no mainTitle is found
//   //         img: randomImages[Math.floor(Math.random() * randomImages.length)], // Random image
//   //       });
//   //     });

//   //     if (newCaseStudies.length > 0) {
//   //       setCaseStudies((prev) => [...prev, ...newCaseStudies]);
//   //       setHasMore(newCaseStudies.length === 3); // If 3 records are returned, we can fetch more
//   //       setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]); // Save the last document for pagination
//   //     } else {
//   //       setHasMore(false); // No more data to fetch
//   //     }
//   //   } catch (error) {
//   //     console.error("Error fetching case studies:", error);
//   //   } finally {
//   //     setLoading(false);
//   //   }
//   // };

//   const fetchCaseStudies = async (page) => {
//     setLoading(true);
//     try {
//       const caseStudiesRef = collection(db, "blogs");
  
//       // Query to fetch data from Firestore without relying on orderBy
//       const caseStudiesQuery = query(
//         caseStudiesRef,
//         where("section", "==", "case_studies"), // Adjust based on your Firestore schema
//         limit(3),
//         ...(page > 1 && lastDoc ? [startAfter(lastDoc)] : []) // Pagination
//       );
  
//       const querySnapshot = await getDocs(caseStudiesQuery);
//       const newCaseStudies = [];
  
//       querySnapshot.forEach((doc) => {
//         const data = doc.data();
//         newCaseStudies.push({
//           id: doc.id,
//           mainTitle: data.mainTitle || "No Title", // Add default title
//           updatedAt: data.updatedAt?.seconds || 0, // Extract updatedAt for sorting
//           viewCount: data.viewCount || 0, // Extract viewCount for sorting
//           img: randomImages[Math.floor(Math.random() * randomImages.length)], // Use random images
//         });
//       });
  
//       if (newCaseStudies.length > 0) {
//         // Combine new data with existing data
//         const combinedData = [...caseStudies, ...newCaseStudies];
  
//         // Sort locally by updatedAt (descending). Fall back to viewCount if needed.
//         const sortedData = combinedData.sort((a, b) => {
//           const dateDiff = b.updatedAt - a.updatedAt; // Sort by updatedAt
//           if (dateDiff === 0) {
//             return b.viewCount - a.viewCount; // Fallback to viewCount
//           }
//           return dateDiff;
//         });
  
//         setCaseStudies(sortedData);
//         setHasMore(newCaseStudies.length === 3); // Update the pagination state
//         setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]); // Update the lastDoc for pagination
//       } else {
//         setHasMore(false); // No more data
//       }
//     } catch (error) {
//       console.error("Error fetching case studies:", error);
//     } finally {
//       setLoading(false);
//     }
//   };
  

//   // const handleBlogClick = (id) => {
//   //   navigate(`/blogIndiView/${id}`); // Navigate to the detailed view
//   // };

//   const handleBlogClick = async (blogId) => {
//     const userId = getUserId(); // Get unique user identifier
//     const viewedBlogs = JSON.parse(localStorage.getItem("viewedBlogs")) || {};
  
//     // Check if the user has already viewed this blog
//     if (!viewedBlogs[blogId]) {
//       try {
//         const blogRef = doc(db, "blogs", blogId);
  
//         // Fetch the blog document
//         const blogDoc = await getDoc(blogRef);
  
//         if (blogDoc.exists()) {
//           // If viewCount doesn't exist, set it to 0 initially, then increment it
//           const currentViewCount = blogDoc.data().viewCount || 0;
          
//           // Use merge: true to ensure other fields stay intact
//           await updateDoc(blogRef, {
//             viewCount: increment(1), // Increment or initialize to 1 if missing
//           });
//           console.log("updated view count success");
//         } else {
//           // If the document doesn't exist, initialize with a viewCount of 1
//           await setDoc(blogRef, { viewCount: 1 }, { merge: true });
//         }
  
//         // Mark blog as viewed locally
//         viewedBlogs[blogId] = true;
//         localStorage.setItem("viewedBlogs", JSON.stringify(viewedBlogs));
//         console.log("updated view count");
//       } catch (error) {
//         console.error("Error updating view count:", error);
//       }
//     }
  
//     // Navigate to the blog's detailed view
//     navigate(`/blogIndiView/${blogId}`);
//   };

//   // Fetch case studies when the component mounts or page changes
//   useEffect(() => {
//     console.log("Fetching case studies..."); // Debugging: Check if fetching starts
//     fetchCaseStudies(page);
//   }, [page]);

//   // Handle Load More button click
//   const handleLoadMore = () => {
//     setPage((prevPage) => prevPage + 1); // Increase the page number
//   };

//   console.log("Case Studies:", caseStudies); // Debugging: Check if case studies are fetched

//   return (
//     <div className="case-studies-container">
//       <h2 className="section-title">Case Studies</h2>
//       <div className="case-studies-grid">
//         {loading
//           ? // Show shimmer effect while loading
//             Array(3)
//               .fill(0)
//               .map((_, index) => (
//                 <div key={index} className="case-study-card">
//                   <Skeleton height={200} />
//                   <Skeleton width="80%" height={30} />
//                 </div>
//               ))
//           : // Show the case studies once loaded
//             caseStudies.map((study) => (
//               <div key={study.id} className="case-study-card"  onClick={() => handleBlogClick(study.id)}>
//                 <img
//                   src={
//                     study.img ||
//                     "https://via.placeholder.com/500x300?text=Default+Image"
//                   } // Use a random image or fallback
//                   alt={study.mainTitle}
//                   className="case-study-image"
//                 />
//                 <div className="case-study-overlay"></div>
//                 <div className="case-study-content">
//                   <p>{study.mainTitle}</p>{" "}
//                   {/* Display the mainTitle instead of title */}
//                 </div>
//               </div>
//             ))}
//       </div>

//       {/* Load More Button */}
//       {hasMore && !loading && (
//         <div className="more-button-container">
//           <button className="more-button" onClick={handleLoadMore}>
//             <span className="dropdown-icon">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="25"
//                 height="25"
//                 fill="currentColor"
//                 viewBox="0 0 16 16"
//               >
//                 <path d="M1.5 5.5l6 6 6-6h-12z" />
//               </svg>
//             </span>
//             Load More
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default CaseStudies;


import React, { useState, useEffect } from "react";
import "./case_studies.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  collection,
  query,
  where,
  getDocs,
  limit,
  startAfter,
  updateDoc,
  increment,
  doc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../img/default_image_sm.png"

const CaseStudies = () => {
  const [caseStudies, setCaseStudies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [lastDoc, setLastDoc] = useState(null); // For pagination
  const navigate = useNavigate();

  // Fetch case studies from Firestore
  const fetchCaseStudies = async () => {
    setLoading(true);
    try {
      const caseStudiesRef = collection(db, "blogs");
      const caseStudiesQuery = query(
        caseStudiesRef,
        where("section", "==", "case_studies"), // Filtering by 'case_studies'
        ...(lastDoc ? [startAfter(lastDoc)] : []), // Pagination using lastDoc
        limit(3) // Load 3 case studies at a time
      );

      const querySnapshot = await getDocs(caseStudiesQuery);
      const newCaseStudies = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(), // Includes `mainTitle`, `cardImage`, and others
      }));

      // setCaseStudies((prev) => [...prev, ...newCaseStudies]);
      const allCaseStudies = [...caseStudies, ...newCaseStudies].sort(
        (a, b) => b.updatedAt?.toMillis() - a.updatedAt?.toMillis()
      );
  
      setCaseStudies(allCaseStudies);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === 3);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]); // Track the last document
      setHasMore(querySnapshot.docs.length === 3); // Check if more data can be loaded
    } catch (error) {
      console.error("Error fetching case studies:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = async (blogId) => {
    try {
      const blogRef = doc(db, "blogs", blogId);

      // Increment the view count for the clicked card
      await updateDoc(blogRef, {
        viewCount: increment(1),
      });

      navigate(`/blogIndiView/${blogId}`); // Navigate to the individual blog view
    } catch (error) {
      console.error("Error updating view count:", error);
    }
  };

  useEffect(() => {
    fetchCaseStudies(); // Fetch initial case studies on component mount
  }, []);

  return (
    <div className="case-studies-container">
      <h2 className="section-title">Case Studies</h2>
      <div className="case-studies-grid">
        {loading
          ? Array.from({ length: 3 }).map((_, index) => (
              <div key={index} className="case-study-card">
                <Skeleton height={200} />
                <Skeleton width="80%" height={30} />
              </div>
            ))
          : caseStudies.map((study) => (
              <div
                key={study.id}
                className="case-study-card"
                onClick={() => handleCardClick(study.id)}
              >
                <img
                  src={study.cardImage || defaultImage} // Use default image if cardImage is null/empty
                  alt={study.mainTitle || "Default Image"}
                  className="case-study-image"
                />
                <div className="case-study-overlay"></div>
                <div className="case-study-content">
                  <p>{study.mainTitle || "Untitled Case Study"}</p>
                </div>
              </div>
            ))}
      </div>

      {hasMore && !loading && (
        <div className="more-button-container">
          <button className="more-button" onClick={fetchCaseStudies}>
            <span className="dropdown-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M1.5 5.5l6 6 6-6h-12z" />
              </svg>
            </span>
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default CaseStudies;
