// import React from "react";
// import "./blog_stepper.css";

// const BlogPreviewStep = ({ blogData }) => {
//   const { section, mainTitle, subContent, titleImage, cardImage } = blogData;

//   return (
//     <div style={{ marginTop: "20px" }}>
//       <h4>Preview</h4>

//       {/* Section and Title */}
//       <p><strong>Section:</strong> {section}</p>
//       <p><strong>Main Title:</strong> {mainTitle}</p>

//       {/* Image Previews */}
//       <div style={{ marginBottom: "20px" }}>
//         <h5>Images</h5>
//         <div style={{ marginBottom: "10px" }}>
//           <h6>Title Image:</h6>
//           <img
//             src={titleImage}
//             alt="Title Preview"
//             style={{ width: "100%", maxHeight: "300px", objectFit: "cover" }}
//           />
//         </div>
//         <div>
//           <h6>Card Image:</h6>
//           <img
//             src={cardImage}
//             alt="Card Preview"
//             style={{ width: "100%", maxHeight: "200px", objectFit: "cover" }}
//           />
//         </div>
//       </div>

//       {/* Sub Content Preview */}
//       <div>
//         <h5>Sub Content:</h5>
//         {subContent.map((item, index) => (
//           <div key={index} style={{ marginBottom: "10px" }}>
//             {item.type === "ParagraphWithTitle" && (
//               <p><strong>Sub Title:</strong> {item.title}</p>
//             )}
//             <p>{item.content}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default BlogPreviewStep;

// import React from "react";
// import "./blog_stepper.css";


// const BlogPreviewStep = ({ blogData }) => {
//   const { section, mainTitle, subContent, titleImage, cardImage } = blogData;

//   return (
//     <div style={{ marginTop: "20px",width: "75%" }}>
//       <h4>Preview</h4>

//       {/* Section and Title */}
//       <p>
//         <strong>Section:</strong> {section}
//       </p>
//       <h4>
//         <strong>{mainTitle}</strong>
//       </h4>

//       {/* Title Image */}
//       <img
//         src={titleImage}
//         alt="Title Preview"
//         className="image-preview"
//         style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" ,width: "100%" }}
//       />

//       {/* SubContent */}
//       <div>
//         {/* <h5>Sub Content</h5> */}
//         {subContent.map((item, index) => (
//           <div key={index}>
//             {item.type === "ParagraphWithTitle" && (
//               <div>
//                 <h5>{item.title}</h5>
//                 <p>{item.content}</p>
//               </div>
//             )}
//             {item.type === "OnlyParagraph" && <p>{item.content}</p>}
//           </div>
//         ))}
//       </div>

//       {/* Card Image */}
//       {/* <div>
//         <h5>Card Image</h5>
//         <img
//           src={cardImage}
//           alt="Card Preview"
//           className="image-preview-sm"
//           // style={{ width: "100%", height: "auto", objectFit: "cover" }}
//         />
//       </div> */}
//     </div>
//   );
// };

// export default BlogPreviewStep;


import React from "react";
import DOMPurify from "dompurify"; // Import DOMPurify
import "./blog_stepper.css";

const BlogPreviewStep = ({ blogData }) => {
  const { section, mainTitle, subContent, titleImage, cardImage } = blogData;

  return (
    <div style={{ marginTop: "20px", width: "75%" }}>
      <h4>Preview</h4>

      {/* Section and Title */}
      <p>
        <strong>Section:</strong> {section}
      </p>
      <h4>
        <strong>{mainTitle}</strong>
      </h4>

      {/* Title Image */}
      <img
        src={titleImage}
        alt="Title Preview"
        className="image-preview"
        style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem", width: "100%" }}
      />

      {/* SubContent */}
      <div>
        {subContent.map((item, index) => (
          <div key={index}>
            {item.type === "ParagraphWithTitle" && (
              <div>
                <h5>{item.title}</h5>
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(item.content),
                  }}
                />
              </div>
            )}
            {item.type === "OnlyParagraph" && (
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(item.content),
                }}
              />
            )}
          </div>
        ))}
      </div>

      {/* Card Image */}
      {/* Uncomment and use this section if you want to preview the card image */}
      {/* <div>
        <h5>Card Image</h5>
        <img
          src={cardImage}
          alt="Card Preview"
          className="image-preview-sm"
        />
      </div> */}
    </div>
  );
};

export default BlogPreviewStep;
