// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";  // Import Firestore SDK
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCpIX7gL7zLIYlbMbIV5muIgj1JOjvbe6k",
  authDomain: "wikiworks-blog.firebaseapp.com",
  projectId: "wikiworks-blog",
  storageBucket: "wikiworks-blog.firebasestorage.app",
  messagingSenderId: "550042618233",
  appId: "1:550042618233:web:6ec3b88358cdaa5c9d07a8",
  measurementId: "G-P6QFPHWE10"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore and export it as 'db'
const db = getFirestore(app);
const storage = getStorage(app);

export { db ,storage};  // Export the Firestore instance
