

// import React, { useState } from "react";
// import BlogContentStep from "./blog_content_step";
// import BlogImageStep from "./blog_image_step";
// import BlogPreviewStep from "./blog_preview_step";
// import Topbar from "../topbar/topbar";
// import {
//   Container,
//   Row,
//   Col,
//   Button,
//   ProgressBar,
//   Spinner,
// } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { collection, addDoc, serverTimestamp } from "firebase/firestore";
// import { db } from "../../firebase";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { useNavigate } from "react-router-dom";

// const steps = ["Content", "Images", "Preview"];

// const CreateBlogStepper = () => {
//   const [activeStep, setActiveStep] = useState(0);
//   const [blogData, setBlogData] = useState({
//     section: "",
//     mainTitle: "",
//     subContent: [],
//     titleImage: "",
//     cardImage: "",
//   });
//   const [isSaving, setIsSaving] = useState(false); // Track saving status
//   const navigate = useNavigate();

//   const handleNext = () => {
//     if (activeStep < steps.length - 1) {
//       setActiveStep((prev) => prev + 1);
//     }
//   };

//   const handleBack = () => {
//     if (activeStep > 0) {
//       setActiveStep((prev) => prev - 1);
//     }
//   };

//   const handleDataUpdate = (updatedData) => setBlogData(updatedData);

//   const handleSubmit = async () => {
//     setIsSaving(true); // Start showing the spinner during the save process

//     // Create a copy of blogData excluding `titleImage` and `cardImage`
//     const { titleImage, cardImage, ...dataToSave } = blogData;

//     // Add a createdAt field with the server timestamp
//     const blogDataWithTimestamp = {
//       ...dataToSave,
//       createdAt: serverTimestamp(),
//     };

//     console.log("Blog data being sent:", blogDataWithTimestamp);

//     try {
//       // Save blog data to Firestore
//       await addDoc(collection(db, "blogs"), blogDataWithTimestamp);

//       // Show success toast
//       toast.success("Blog saved successfully!", {
//         position: "top-center",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         style: { marginTop: "100px" },
//       });

//       // Redirect to /adminBlogList after a short delay
//       setTimeout(() => navigate("/adminBlogList"), 3000);
//     } catch (error) {
//       console.error("Error saving blog: ", error);
//       toast.error("Failed to save blog. Please try again.", {
//         position: "top-right",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//       });
//     } finally {
//       setIsSaving(false); // Hide the spinner when done
//     }
//   };

//   return (
//     <div>
//       <Topbar />
//       <Container fluid className="p-3 create-blog-container">
//         <ToastContainer />
//         <h2 className="text-center marg-l">Create Blog</h2>

//         {/* Global Spinner (Center of Screen) */}
//         {isSaving && (
//           <div
//             style={{
//               position: "fixed",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               zIndex: 1050, // Ensure it's on top of other UI elements
//             }}
//           >
//             <Spinner animation="border" role="status" variant="primary">
//               <span className="visually-hidden">Saving...</span>
//             </Spinner>
//           </div>
//         )}

//         {/* Stepper Panel */}
//         <Row className="mb-4">
//           <Col>
//             <ProgressBar now={(activeStep / (steps.length - 1)) * 100} />
//             <div className="d-flex justify-content-between mt-3">
//               {steps.map((label, index) => (
//                 <div
//                   key={label}
//                   className={`text-center ${
//                     index === activeStep
//                       ? "text-primary fw-bold"
//                       : index < activeStep
//                       ? "text-success fw-bold"
//                       : "text-muted"
//                   }`}
//                 >
//                   <div
//                     className={`rounded-circle d-inline-flex align-items-center justify-content-center mb-2 ${
//                       index <= activeStep ? "bg-primary text-white" : "bg-light"
//                     }`}
//                     style={{
//                       width: "30px",
//                       height: "30px",
//                       border: "1px solid #ccc",
//                     }}
//                   >
//                     {index + 1}
//                   </div>
//                   <div>{label}</div>
//                 </div>
//               ))}
//             </div>
//           </Col>
//         </Row>

//         {/* Step Content */}
//         <Row>
//           <Col>
//             {activeStep === 0 && (
//               <BlogContentStep
//                 blogData={blogData}
//                 onUpdate={handleDataUpdate}
//               />
//             )}
//             {activeStep === 1 && (
//               <BlogImageStep blogData={blogData} onUpdate={handleDataUpdate} />
//             )}
//             {activeStep === 2 && <BlogPreviewStep blogData={blogData} />}
//           </Col>
//         </Row>

//         {/* Navigation Buttons */}
//         <Row className="mt-4">
//           <Col className="d-flex justify-content-between">
//             <Button
//               style={{ width: "10%" }}
//               variant="secondary"
//               onClick={handleBack}
//               disabled={activeStep === 0}
//             >
//               Back
//             </Button>
//             {activeStep === steps.length - 1 ? (
//               <Button
//                 style={{ width: "10%" }}
//                 variant="primary"
//                 onClick={handleSubmit}
//                 disabled={isSaving} // Disable button when saving
//               >
//                 {isSaving ? "Saving..." : "Save"}
//               </Button>
//             ) : (
//               <Button
//                 style={{ width: "10%" }}
//                 variant="primary"
//                 onClick={handleNext}
//                 disabled={isSaving} // Disable button when saving
//               >
//                 Next
//               </Button>
//             )}
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// };

// export default CreateBlogStepper;


import React, { useState } from "react";
import BlogContentStep from "./blog_content_step";
import BlogImageStep from "./blog_image_step";
import BlogPreviewStep from "./blog_preview_step";
import Topbar from "../topbar/topbar";
import {
  Container,
  Row,
  Col,
  Button,
  ProgressBar,
  Spinner,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const steps = ["Content", "Images", "Preview"];

// Helper to convert file to base64
const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file) {
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    } else {
      reject("File not provided.");
    }
  });
};

const CreateBlogStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [blogData, setBlogData] = useState({
    section: "",
    mainTitle: "",
    subContent: [],
    titleImage: null, // Either File or base64 string
    cardImage: null, // Either File or base64 string
  });
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  const handleDataUpdate = (updatedData) => {
    setBlogData((prev) => ({
      ...prev,
      ...updatedData,
    }));
  };

  const handleSubmit = async () => {

    if (!blogData.mainTitle && !blogData.content && !blogData.titleImage && !blogData.cardImage) {
      toast.error("Please fill in all fields before submitting!", {
        position: "top-center",
        autoClose: 3000,
        style: { marginTop: "100px" },
      });
      return; // Stop execution if validation fails
    }
    setIsSaving(true);
    try {
      // Convert images to base64 if they are File objects
      const titleImageBase64 =
        blogData.titleImage instanceof File
          ? await fileToBase64(blogData.titleImage)
          : blogData.titleImage;

      const cardImageBase64 =
        blogData.cardImage instanceof File
          ? await fileToBase64(blogData.cardImage)
          : blogData.cardImage;

      // Prepare the blog data to save
      const blogDataWithTimestamp = {
        ...blogData,
        titleImage: titleImageBase64,
        cardImage: cardImageBase64,
        createdAt: serverTimestamp(),
      };

      // Add to Firestore
      await addDoc(collection(db, "blogs"), blogDataWithTimestamp);

      toast.success("Blog created successfully!", {
        position: "top-center",
        autoClose: 3000,
        style: { marginTop: "100px" },
      });

      setTimeout(() => navigate("/adminBlogList"), 3000);
    } catch (error) {
          console.error("Error saving blog:", error);
          if(error.message.includes("1048487")){
            toast.error(`Please Upload Image less than 450 kb`, {
              position: "top-center",
              autoClose: 3000,
              style: { marginTop: "100px" },
            });
    
          }else{
            toast.error(`Failed to save blog. Please try again.`, {
              position: "top-center",
              autoClose: 3000,
              style: { marginTop: "100px" },
            });
          }
         
        } finally {
      setIsSaving(false);
    }
  };

  return (
    <div>
      <Topbar />
      <Container fluid className="p-3 create-blog-container">
        <ToastContainer />
        <h2 className="text-center">Create Blog</h2>

        {/* Spinner */}
        {isSaving && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1050,
            }}
          >
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Saving...</span>
            </Spinner>
          </div>
        )}

        {/* Stepper Progress */}
        <Row className="mb-4">
          <Col>
            <ProgressBar now={(activeStep / (steps.length - 1)) * 100} />
            <div className="d-flex justify-content-between mt-3">
              {steps.map((label, index) => (
                <div
                  key={label}
                  className={`text-center ${
                    index === activeStep
                      ? "text-primary fw-bold"
                      : index < activeStep
                      ? "text-success fw-bold"
                      : "text-muted"
                  }`}
                >
                  <div
                    className={`rounded-circle d-inline-flex align-items-center justify-content-center mb-2 ${
                      index <= activeStep ? "bg-primary text-white" : "bg-light"
                    }`}
                    style={{
                      width: "30px",
                      height: "30px",
                      border: "1px solid #ccc",
                    }}
                  >
                    {index + 1}
                  </div>
                  <div>{label}</div>
                </div>
              ))}
            </div>
          </Col>
        </Row>

        {/* Step Content */}
        <Row>
          <Col>
            {activeStep === 0 && (
              <BlogContentStep blogData={blogData} onUpdate={handleDataUpdate} />
            )}
            {activeStep === 1 && (
              <BlogImageStep blogData={blogData} onUpdate={handleDataUpdate} />
            )}
            {activeStep === 2 && <BlogPreviewStep blogData={blogData} />}
          </Col>
        </Row>

        {/* Navigation Buttons */}
        <Row className="mt-4">
          <Col className="d-flex justify-content-between">
            <Button
              style={{ width: "10%" }}
              variant="secondary"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              Back
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button
                style={{ width: "10%" }}
                variant="primary"
                onClick={handleSubmit}
                disabled={isSaving}
              >
                {isSaving ? "Saving..." : "Save"}
              </Button>
            ) : (
              <Button
                style={{ width: "10%" }}
                variant="primary"
                onClick={handleNext}
              >
                Next
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreateBlogStepper;
