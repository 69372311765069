import "../extraPages/hcm/hcm.css";
import Contact_us from "../contact_us/contact_us";
import Topbar from "../topbar/topbar";
import AdminLoginDetail from "../admin_main/admin_login_detail";
// import TopBar from "../topbar/topbar";
import Footer from "../Footer/Footer";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export default function AdminLogin({ openModal }) {
  const solutionsAndServicesRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    // Scroll to the solutionsAndServicesRef element if it exists
    if (solutionsAndServicesRef.current) {
      solutionsAndServicesRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [location]);

  const handleGoBack = () => {
    window.history.back(); // Go back in the browser's history
  };
  return (
    <div id="adminLogin">
      <Topbar />
      <AdminLoginDetail />
      {/* <Contact_us /> */}
      <Footer />
    </div>
  );
}
