import React, { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../extraPages/hcm/hcm.css";
import Contact_us from "../contact_us/contact_us";
import Topbar from "../topbar/topbar";
import Footer from "../Footer/Footer";
import "./admin_login_detail.css";
import img1 from "../../img/blog_menu.png";
import img2 from "../../img/career_menu.png";

export default function AdminMenu() {
  const solutionsAndServicesRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is logged in when the page loads
    const isLoggedIn = sessionStorage.getItem("isLoggedIn");
    if (!isLoggedIn) {
      // Redirect to login if the user is not logged in
      navigate("/adminLogin"); 
    }

    // Optionally scroll to the solutionsAndServicesRef if it's part of the current page logic
    if (solutionsAndServicesRef.current) {
      solutionsAndServicesRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [location, navigate]);

  const handleGoBack = () => {
    window.history.back(); // Go back in the browser's history
  };

  const handleImageClick = () => {
    navigate("/adminBlogList"); // Navigate to the BlogPage when image is clicked
  };


  return (
    <div id="adminMenu">
      <Topbar />
      <h2 className="menu-header">Edit</h2>
      <div className="menu-container">
        <div className="menu-images">
          <img src={img1} onClick={handleImageClick} style={{ cursor: "pointer" }}  alt="Blogs" className="menu-image" />
          <img src={img2} alt="Careers" style={{ cursor: "pointer" }}  className="menu-image" />
        </div>
      </div>
      <Footer />
    </div>
  );
}
