import React from "react";
import "./blogs_user.css";
import pr2 from "../../img/Designer (8) 1.png";
import coreImg from "../../img/Blogs_header.png";
import pr3 from "../../img/Feature 2.png";
import pr4 from "../../img/Feature 3.png";
// import pr4 from "../../img/wikiworks logo 230725-01 2.png";
import "../our_story/ourStory.css";
import CaseStudiesContainer from "../case_studies/case_studies";
import Blogs from "../blogs/blogs";

import { Swiper, SwiperSlide } from "swiper/react";
import CertifiedPartner from "../certified_partner/certified_partner";
import ServiceOfferings from "../service_offering/service_offerings";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function BlogUser({ openModal }) {
  return (
    <div className="whoWeAreMain-o-u">
      {/* <div className="who-we-are-main-head-o">Features</div> */}
      <div className="core-value-cont-u">
        <div className="core-value-up-u">
          <img className="core-img-u" src={coreImg} alt="" />
        </div>
      </div>

      <div id="WhoWeAre" className="whoWeAreUp-o">
        <div className="whoWeAreRight-o">
          <h1 className="whoWeAreHeader-o">Our Story</h1>

          <div className="whoWeAreText-o">
            <p
              style={{
                wordWrap: "break-word", // Wrap long words to avoid overflow
                whiteSpace: "normal", // Allow text to wrap and break lines
              }}
            >
              At WikiWorks, we are a B2B SaaS company dedicated to transforming
              the HR tech landscape, with a particular focus on contract
              workforce management. Our solutions empower organizations to
              streamline the complexities of managing a contract workforce, from
              onboarding and compliance to performance tracking.
            </p>
          </div>
        </div>

        <div className="whoWeAreLeft-o">
          <img src={pr2} alt="pr2" />
        </div>
      </div>

      <CaseStudiesContainer />
      <Blogs />
    </div>
  );
}

export default BlogUser;
